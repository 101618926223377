<template>
  <div class="text-center">
    <p style="font-size: 12px;">GeoPolitical Sensitivity Index : {{ selectedgeopol }}</p>
    <div style="width: 100%;" class="d-flex align-items-center justify-content-center mb-3">
        <span v-if="dataBrut.length != 0" class="me-2" style="font-size: 12px;">{{ dataBrut.gpsi[0].myvalue.toPrecision(3) }}</span>
        <div class="progress" style="width: 50%;">
          <div
              v-if="dataBrut.length != 0"
              class="progress-bar"
              role="progressbar"
              :style="{ width: dataBrut.gpsi[0].myvalue + '%', backgroundColor: getProgressColor(dataBrut.gpsi[0].myvalue) }"
              :aria-valuenow="dataBrut.gpsi[0].myvalue"
              aria-valuemin="0"
              aria-valuemax="100"
          ></div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between me-4 ms-4" style="gap: 40px;">
    <div class="text-center" style="width: 100%;">
      <p style="font-size: 12px;color: #0065c2;">Alliances</p>
      <div style="width: 100%;" class="d-flex align-items-center">
        <span v-if="dataBrut.length != 0" class="me-2" style="font-size: 12px;">{{ dataBrut.alliances[0].myvalue.toPrecision(3) }}</span>
        <div class="progress" style="width: 100%;">
          <div
              v-if="dataBrut.length != 0"
              class="progress-bar"
              role="progressbar"
              :style="{ width: dataBrut.alliances[0].myvalue + '%', backgroundColor: getProgressColor(dataBrut.alliances[0].myvalue) }"
              :aria-valuenow="dataBrut.alliances[0].myvalue"
              aria-valuemin="0"
              aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
    <div class="text-center" style="width: 100%;">
      <p style="font-size: 12px;color:#00d23a;">Cur. Conflics & Santions</p>
      <div style="width: 100%;" class="d-flex align-items-center">
        <span v-if="dataBrut.length != 0" class="me-2" style="font-size: 12px;">{{ dataBrut.cur_conf_sanct[0].myvalue.toPrecision(3) }}</span>
        <div class="progress" style="width: 100%;">
          <div
              v-if="dataBrut.length != 0"
              class="progress-bar"
              role="progressbar"
              :style="{ width: dataBrut.cur_conf_sanct[0].myvalue + '%', backgroundColor: getProgressColor(dataBrut.cur_conf_sanct[0].myvalue) }"
              :aria-valuenow="dataBrut.cur_conf_sanct[0].myvalue"
              aria-valuemin="0"
              aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
    <div class="text-center" style="width: 100%;">
      <p style="font-size: 12px;color:#00b0c2">Crit. Lock & Knots</p>
      <div style="width: 100%;" class="d-flex align-items-center">
        <span v-if="dataBrut.length != 0" class="me-2" style="font-size: 12px;">{{ dataBrut.crit_lock_knots[0].myvalue.toPrecision(3) }}</span>
        <div class="progress" style="width: 100%;">
          <div
              v-if="dataBrut.length != 0"
              class="progress-bar"
              role="progressbar"
              :style="{ width: dataBrut.crit_lock_knots[0].myvalue + '%', backgroundColor: getProgressColor(dataBrut.crit_lock_knots[0].myvalue) }"
              :aria-valuenow="dataBrut.crit_lock_knots[0].myvalue"
              aria-valuemin="0"
              aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div id="radarGeo"></div>
  </div>
</template>

<script setup>
/* eslint-disable */
import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { onMounted, defineProps, ref, watch } from 'vue';
 import api from '@/services/api'
 
const props = defineProps({
  selectedgeopol: {
    type: String,
    required: true,
  },
});

const geopol = ref(props.selectedgeopol);
const dataBrut = ref([]);

function getProgressColor(value) {
    if (value > 60) {
        return '#a82418'; // Dark red
    } else if (value > 55) {
        return '#c23728'; // Medium dark red
    } else if (value > 50) {
        return '#e48b87'; // Intermediate red
    } else if (value > 45) {
        return '#f2cacb'; // Light red
    } else if (value > 40) {
        return '#faf1d2'; // Yellow
    } else if (value > 35) {
        return '#b2daf5'; // Light blue
    } else if (value > 30) {
        return '#85cdf7'; // Intermediate blue
    } else if (value > 25) {
        return '#4f99cc'; // Darker intermediate blue
    } else {
        return '#004085'; // Very dark blue
    }
  }

watch(
  () => props.selectedgeopol,
  (newVal) => {
    geopol.value = newVal;
    fetchData();
  }
);

const fetched = ref(false);

const fetchData = async () => {
  await api.get("/data/loadviews/geopol/" + geopol.value)
    .then(response => {
      dataBrut.value = response.data.data;
      const formattedData = formatData(response.data.data);
      fetched.value = true;
      setChartData(formattedData);
    })
    .catch((error) => {
      console.error(error);
    });
};

const formatData = (data) => {
  return [
    {
      geopol: "Consistency",
      value: data.alliances_consistency[0] ? data.alliances_consistency[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Geographies",
      value: data.geographies[0] ? data.geographies[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Anti-Western Polarization",
      value: data.anti_western_polar[0] ? data.anti_western_polar[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Conflicts",
      value: data.cur_conflict_intens[0] ? data.cur_conflict_intens[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Geopolitical Buzz",
      value: data.geopol_buzz[0] ? data.geopol_buzz[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Sanctions",
      value: data.sanctions[0] ? data.sanctions[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Technology",
      value: data.technology[0] ? data.technology[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Ressources",
      value: data.resources[0] ? data.resources[0].myvalue.toPrecision(3) : 0,
    },
    {
      geopol: "Networks",
      value: data.networks[0] ? data.networks[0].myvalue.toPrecision(3) : 0,
    },
  ];
};

let root = null; // Stocke l'instance Root globalement

const setChartData = (data) => {
  if (!data.length) return;

  // Dispose l'instance Root existante si elle est déjà créée
  if (root) {
    root.dispose();
    root = null; // Réinitialise la variable root
  }

  am5.ready(() => {
    // Crée une nouvelle instance de Root
    root = am5.Root.new("radarGeo");

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5radar.RadarChart.new(root, {
      panX: false,
      panY: false
    }));

    const cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", true);

    const xRenderer = am5radar.AxisRendererCircular.new(root, {});
    xRenderer.labels.template.setAll({
      radius: 10,
      fill: am5.color(0xff0000),
    });

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5radar.AxisRendererRadial.new(root, {}),
      min: 0,
      max: 100,
      strictMinMax: true,
    }));

    yAxis.get("renderer").labels.template.setAll({
      visible: true,
      fontSize: 12 
    });

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: "geopol",
      renderer: xRenderer
    }));

    xAxis.get("renderer").labels.template.adapters.add("fill", (fill, target) => {
      const dataItem = target.dataItem;
      if (dataItem) {
        const category = dataItem.get("category");

        if (category === "Technology" || category === "Networks" || category === "Ressources") {
          return am5.color("#00b0c2");
        }
        if (category === "Geographies" || category === "Consistency" || category === "Anti-Western Polarization") {
          return am5.color("#0065c2");
        }
        if (category === "Conflicts" || category === "Sanctions" || category === "Geopolitical Buzz") {
          return am5.color("#00d23a");
        }
      }

      return fill;
    });

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 12
    });

    const series = chart.series.push(am5radar.RadarLineSeries.new(root, {
      name: "Series",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      categoryXField: "geopol",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}",
        keepTargetHover: true
      })
    }));

    series.strokes.template.setAll({
      strokeWidth: 2
    });

    series.data.setAll(data);
    xAxis.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);
  });
};

onMounted(() => {
  fetchData();
});
</script>

<style scoped>
  .progress {
    height: 0.7rem;
  }

#radarGeo {
  width: 100%;
  height: 400px;
}
</style>