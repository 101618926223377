<template>
  <div class="blocDashboardsList">
    <h2 class="pt-3">Dashboards</h2>
    <hr>
    <div class="row row-cols-1 row-cols-md-3 row-cols-xl-5 row-cols-sm-3">
      <div class="block col" v-for="item in dashboards" :key="item.id">
        <a v-bind:href="'/dashboards/' + item.id">
          <div class="row">
            <div class="col">
              <div class="card justify-content-center align-items-center border shadow" style="height: 350px; width: 100%;">
                <img class="card-img-top" :src="'/img/dashboards/' + (item.picture).slice(0, -4) + '.jpg'" alt="Image Dashboard" height="150">
                <div class="card-body">
                  <h4 class="name pt-3">{{ item.name }}</h4>
                  <div class="description text-muted" v-html="item.description"></div>
                  <!-- <div class="d-flex justify-content-between align-items-center">
                  <small class="text-muted">Updated on XX</small>
              </div> -->
                </div>
              </div>
            </div>
          </div>
        </a>
        <!-- <a v-else>
        <div class="row premium">
          <div class="col">
            <div class="card h-70 justify-content-center align-items-center border shadow">
              <div class="opacity">
                <div class="text">
                  <p>Subscription Required</p>
                  <p>Subscribe <a href="http://app.taceconomics.com/pricing">here</a></p>
                </div>
              </div>
              <img class="card-img-top" :src="'/img/dashboards/' + item.id + '.jpg'" alt="Image Dashboard" height="150">
              <div class="card-body">
                <h4 class="name">{{ item.name }}</h4>
                <div class="description text-muted">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </a> -->
      </div>
    </div>

    <!-- <div class="text-center h1 mt-4">Dashboards</div>
  <div class="album py-5 bg-light">
    <div class="container">
      <div class="col" v-for="item in dashboards" :key="item.id">
        <a v-bind:href="'/dashboards/'+item.id">
          <div class="row">
            <div class="col-md-4">
              <div class="card h-100 justify-content-center align-items-center border shadow p-2">
                <img class="card-img-top" src="https://images.unsplash.com/photo-1678436749270-c3c66a346bf3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTZ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=1200&q=60" alt="Card image cap">
                <div class="card-body">
                  <p class="card-text">{{item.name}}</p>
                  <small class="text-muted">{{item.description}}</small>
                  <div class="d-flex justify-content-between align-items-center">
                      <small class="text-muted">Updated on XX</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>  
  </div>
   -->
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  data() {
    return {
      dashboards: {},
      code_access: null,
    }
  },
  methods: {
    async load() {
      await api.get("/dashboards/")
        .then(response => this.dashboards = response.data)
        .catch((error) => {
          this.code_access = error.response.status;
        });
    }
  },
  async mounted() {
    await this.load()
  }
}
</script>

<style scoped>
.blocDashboardsList {
  padding: 0 10%;
}

.block {
  transition: 0.3s ease-in-out;
  padding: 1em;
}

.block:hover {
  transform: scale(1.05);
}

.block:active {
  transform: scale(1.02);
}

.opacity {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.547);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  text-align: center;
}

.text p {
  margin: 0;
}

.text p a {
  text-decoration: underline;
}

.opacity .text {
  color: white;
  font-size: 1.2em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(255, 0, 0, 0.255);
  width: 100%;
  height: 150px;
  padding-top: 1em;
  transition: all 0.2s ease-in-out;
}

.card-body {
  height: 140px;
  transition: all 0.2s ease-in-out;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
}

.card {
  border-radius: 10px;
}

.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.description {
  font-size: 14px;
}


@media screen and (max-width: 1400px) {
  .opacity .text {
    font-size: 1em;
  }
}

@media screen and (max-width: 1200px) {
  .description {
    font-size: 13px;
  }
}

@media screen and (max-width: 991px) {
  .name {
    font-size: 12px;
  }

  .description {
    font-size: 11px;
  }

  .opacity .text {
    font-size: 1em;
  }
}

@media screen and (max-width: 767px) {
  .name {
    font-size: 15px;
  }

  .description {
    font-size: 12px;
  }

  .opacity .text {
    font-size: 1.4em;
  }
}
</style>