<template>
    <div class="w-75 mx-auto" id="chartDiv" style="height: 220px;"></div>    
</template>

<script setup>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import { onMounted, defineProps, watch } from "vue";

const props = defineProps({
    indicatorE: Number
})


let chart;

onMounted(() => {
    renderChart()
})

watch(() => props.indicatorE, () => {
    chart.dispose();
    renderChart();
})

function renderChart() {
    // Create chart instance
    chart = am4core.create("chartDiv", am4charts.XYChart);
    chart.height = am4core.percent(100);

    // Add title at the top of the chart
    let title = chart.titles.create();
    title.text = "ESG Country Score";
    title.fontSize = 26;
    title.align = "center";
    title.fill = am4core.color("#000");
    title.marginBottom = 20;

    // Add data
    chart.data = [{
        "category": "Score",
        "part1": 1,
        "part2": 1,
        "part3": 1,
        "part4": 1,
        "part5": 1
    }];

    var midContainer = chart.chartContainer.createChild(am4core.Container);
    midContainer.toBack();
    midContainer.paddingBot = 40;
    midContainer.marginBottom = 40;
    midContainer.width = am4core.percent(100);
    midContainer.height = am4core.percent(100);

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.height = am4core.percent(55);
    
    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = 5;
    valueAxis.renderer.labels.template.disabled = true;

    // Create series
    createSeries("part1", "Low", "#cc0000", 1, 2.5);
    createSeries("part2", "Moderately Low", "#eb4b52", 2.51, 3);
    createSeries("part3", "Moderately High", "#f1c232", 3.01, 3.5);
    createSeries("part4", "High", "#3be33b", 3.51, 4);
    createSeries("part5", "Very High", "#029602", 4.01, 5);
}

function createSeries(field, name, color, min, max) {
    // Crée la série de colonnes
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = field;
    series.dataFields.categoryY = "category";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.paddingRight = 3;
    series.name = name;
    series.stacked = true;
    series.columns.template.fill = am4core.color(color);
    
    // Crée le conteneur du symbole Pin
    let pinContainer = series.createChild(am4core.Container);
    let pinBullet = pinContainer.createChild(am4core.Sprite);
    pinBullet.path = "M510 1262 c-194 -51 -320 -219 -320 -428 0 -102 84 -292 231 -522 96 -151 205 -302 218 -302 25 0 291 404 365 554 100 205 110 326 37 481 -84 181 -312 274 -531 217z";
    pinBullet.scale = 0.05;
    pinBullet.rotation = 180;
    pinBullet.horizontalCenter = "middle";
    pinBullet.verticalCenter = "top";
    pinBullet.fill = getColor(props.indicatorE);

    // Crée une étiquette pour afficher la valeur de l'indicateur au-dessus du symbole Pin
    let label = pinContainer.createChild(am4core.Label);
    label.text = props.indicatorE;
    label.fill = am4core.color("#FFFFFF");
    label.fontSize = 16;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.dy = -28; 

    series.events.on("validated", function() {
        if (props.indicatorE >= min && props.indicatorE <= max) {
            pinContainer.visible = true;
            let column = series.columns.getIndex(0); 
            let xPosition = column.pixelX + column.width / 2;
            pinBullet.moveTo({ x: xPosition, y: -10 });
            label.moveTo({ x: xPosition, y: -10 });
        } else {
            pinContainer.visible = false;
        }
    });
}



function getColor(value) {
    if (value <= 2.5) {
        return am4core.color("#cc0000");
    } else if (value <= 3) {
        return am4core.color("#eb4b52");
    } else if (value <= 3.5) {
        return am4core.color("#f1c232");
    } else if (value <= 4) {
        return am4core.color("#3be33b");
    } else if (value <= 5) {
        return am4core.color("#029602");
    } else {
        return am4core.color("#808080");
    }
}
</script>

<style scoped>

</style>