<template>
  <div class="tabs pt-2" :style="{ backgroundImage: `linear-gradient(to right, rgb(245, 245, 245) 20%, rgba(255, 255, 255, 0) 100%), url(${props.backgroundImage})`}">
    <div style="width: 100%;">
      <div class="d-flex align-items-center mt-5 ms-5" style="height: 25px; margin-top: 50px !important;">
        <h2 style="font-weight: bold;font-size: 3rem;">{{ props.name }}</h2>
      </div>
      <div class="d-flex mt-5 ms-5">
        <template v-for="(tab, index) in tabs" :key="tab.key">
          <p style="font-size: 1.2rem;"
            :class="{ active: props.activeTab === tab.key }"
            @click="setActive(tab.key)"
          >
            {{ tab.label }}
          </p>
          <span v-if="index < tabs.length - 1" class="separator">|</span>
        </template>
      </div>
    </div>
  </div>
  <slot :activeTab="props.activeTab"></slot>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  defaultActiveTab: {
    type: String,
    default: 'tab1',
  },
  backgroundImage: {
    type: String,
    default: '',
  },
  activeTab: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:activeTab']);

const setActive = (tab) => {
  emit('update:activeTab', tab);
};
</script>

<style scoped>
.tabs {
  display: flex;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tabs p {
  text-align: center;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: -1px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(196, 196, 196, 0.258);
  transition: color 0.3s;
  max-height: 200px;
}

.tabs p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(0, 134, 218);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.tabs p.active {
  color: rgb(0, 134, 218);
}

.tabs p.active::after {
  transform: scaleX(1);
}

.separator {
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
</style>
