<template>
  <div class="loading_screenshot">
    <div class="capture_loading">
      <p class="screen_progress">Screenshot in progress</p>
    </div>
  </div>
  <div v-if="code_access != 403 && code_access != 404" class="page_dashboard">
    <!-- ############ Titre du Dashboard ############ -->
    <div class="title_dashboard">
      <div class="titles">
        <h2 class="mb-1 dashboard_name">
          <h2 style="margin-right: 7px" class="mb-1 country_selected" v-if="selectedCountry != null">{{ selectedCountry }}
          </h2>
          <h2 class="mb-1 country_selected" v-else></h2>{{ dashboard.name }}
        </h2>
        <h3 class="mb-2" v-html = dashboard.description ></h3>
      </div>

      <div v-if="!dashboard.multi_country">

        <button v-if="user.email.endsWith('@taceconomics.com') || user.subscriptions.length > 0"
          @click="download_dashboard" class="download_dashboard" :class="{ hovered: isHovered }">
          <i class="fa-solid fa-file-image"></i>
        </button>

        <div v-else class="buttons_disabled alert alert-warning">
          <p>You need a <strong>premium</strong>
            subscription to be able to download
            the entire dashboard picture. You can
            see the
            different subscription
            plans <a href="http://app.taceconomics.com/pricing">here</a>.</p>
        </div>

      </div>

      <!-- ############ Sélectionneur de pays ############ -->
      <div class="select_country" v-else>

        <button v-if="user.email.endsWith('@taceconomics.com') || user.subscriptions.length > 0"
          @click="download_dashboard" class="download_dashboard" :class="{ hovered: isHovered }">
          <i class="fa-solid fa-file-image"></i>
        </button>

        <div v-else class="buttons_disabled alert alert-warning">
          <p>You need a <strong>premium</strong>
            subscription to be able to download
            the entire dashboard picture. You can
            see the
            different subscription
            plans <a href="http://app.taceconomics.com/pricing">here</a>.</p>
        </div>

        <div class="popup" v-if="checked.countries == null">Select a country <i class="fa-solid fa-arrow-right"></i>
        </div>
        <div class="p-1">
          <div class="dropdown">
            <button class="dropdown-toggle country-btn" type="button" id="periodicityDropdown"
              data-bs-toggle="dropdown">Country</button>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-center p-3">
              <input class="form-control form-control-sm mb-1" type="text" placeholder="Search country"
                v-model="searchTerm" @input="onSearchInput">
              <template v-for="country in filteredCountries" :key="country.id">
                <li class="checkboxCountry" v-if="country.short_name.toLowerCase().includes(searchTerm.toLowerCase())">
                  <label class="" :for="country.id">
                    <input :id="country.id" type="radio" @click="toggleRadioButton($event)" ref="firstCountry"
                      :value="country.id" v-model="checked.countries" @change="onFilterChange">
                    {{ country.short_name }}
                  </label>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>

    </div>
    <hr>

    <!-- ############ Graphique ############ -->

    <!-- Si le graphique est une carte du monde -->
    <div v-if="this.firstElementType == 'Map'">

      <!-- On affiche en premier que la carte -->
      <template v-for="item in dashboard.content" :key="item.symbol">
        <div class="map_block">
          <div v-if="item.type == 'Map'" :class="item.size_chart === 'large' ? 'col-md-12' : 'col-md-6'" class="py-2">
            <ChartLines :key="this.year_map" :graph_title="item.graph_title" :graph_subtitle="item.graph_subtitle"
              :from="item.start_date" :end="item.end_date" :serie="item.codes" :legend="item.legend" :colors="item.colors"
              :scrollbar="false" :collapse="item.collapse" :mode_collapse="item.mode_collapse" :type="item.type"
              :heatmap_conditions="item.heatmap_conditions" :stacked="item.stacked"
              :stacked_total_line="item.stacked_total_line" :grey_area="item.grey_area" :title_area="item.title_area"
              :multi_country="dashboard.multi_country" :country_stat="checked.countries" :countries="countries"
              :source="item.source" :right="item.right" :codes_right="item.codes_right" :last_date="item.last_date"
              :dimensions="dashboard.dimensions" :year_map="this.year_map" :decimals="item.decimals"
              @country-selected-changed="handleCountrySelectedChange"/>
          </div>
        </div>

        <!-- Liste des années pour la carte du monde -->
          <div v-if="item.type === 'Map'" class="btnYears_Map w-100 text-center position-relative">
            <div id="carouselOfYears" class="carousel carousel-dark slide h-100" data-bs-interval="false">
              <div class="carousel-inner top-50 start-50 translate-middle">
                <div v-for="(group, index) in groupedYears" :key="index" class="carousel-item" :class="{ 'active': index === groupedYears.length - 1 }">
                  <button v-for="year in group" :key="year" :class="['btn_year', { 'selected': year_map === `${year}-01-01` }]" :value="`${year}-01-01`" @click="changeYearMap($event)" class="mx-2">
                    {{ year }}
                  </button>
                </div>
              </div>
              <button class="carousel-control-prev justify-content-end" type="button" data-bs-target="#carouselOfYears" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next justify-content-start" type="button" data-bs-target="#carouselOfYears" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
      </template>

      <!-- On affiche les autres graphiques après la carte -->
      <div class="row dashboard_chartlines" v-if="!dashboard.multi_country || checked.countries != null">
        <template v-for="item in withoutfirstElementType" :key="item.symbol">
          <div v-if="item.codes" :class="item.size_chart === 'large' ? 'col-md-12' : 'col-md-6'" class="py-2">
            <ChartLines :key="checked.countries" :graph_title="item.graph_title" :graph_subtitle="item.graph_subtitle"
              :from="item.start_date" :end="item.end_date" :serie="item.codes" :legend="item.legend" :colors="item.colors"
              :scrollbar="false" :collapse="item.collapse" :mode_collapse="item.mode_collapse" :type="item.type"
              :heatmap_conditions="item.heatmap_conditions" :stacked="item.stacked"
              :stacked_total_line="item.stacked_total_line" :grey_area="item.grey_area" :title_area="item.title_area"
              :multi_country="dashboard.multi_country" :country_stat="checked.countries" :source="item.source"
              :right="item.right" :codes_right="item.codes_right" :last_date="item.last_date"
              :dimensions="dashboard.dimensions" :decimals="item.decimals" :year_map="this.year_map"
              :yInterval="item.yInterval" :xInterval="item.xInterval" :corners_labels="item.corners_labels" />
          </div>
          <div v-else class="col-md-6 py-2">
            <ChartLine :dataset="item.dataset" :symbol="item.symbol" :params="item.params" :transform="item.transform"
              :start_date="item.start_date" :color="item.color" :scrollbar="false" />
          </div>
        </template>
      </div>
    </div>

    <!-- Configuration Normale pour n'importe quel dashboard sans carte du monde -->
    <div v-else>
      <div class="row dashboard_chartlines" v-if="!dashboard.multi_country || checked.countries != null">
        <template v-for="item in dashboard.content" :key="item.symbol">

          <div v-if="item.codes" :class="item.size_chart === 'large' ? 'col-md-12' : 'col-md-6'" class="py-2">
            <ChartLines :key="checked.countries" :graph_title="item.graph_title" :graph_subtitle="item.graph_subtitle"
              :from="item.start_date" :end="item.end_date" :serie="item.codes" :legend="item.legend" :colors="item.colors"
              :scrollbar="false" :collapse="item.collapse" :mode_collapse="item.mode_collapse" :type="item.type"
              :heatmap_conditions="item.heatmap_conditions" :stacked="item.stacked"
              :stacked_total_line="item.stacked_total_line" :grey_area="item.grey_area" :title_area="item.title_area"
              :multi_country="dashboard.multi_country" :country_stat="checked.countries" :source="item.source"
              :right="item.right" :codes_right="item.codes_right" :last_date="item.last_date"
              :dimensions="dashboard.dimensions" :decimals="item.decimals" />
          </div>
          <div v-else class="col-md-6 py-2">
            <ChartLine :dataset="item.dataset" :symbol="item.symbol" :params="item.params" :transform="item.transform"
              :start_date="item.start_date" :color="item.color" :scrollbar="false" />
          </div>
        </template>
      </div>
    </div>

  </div>

  <!-- Si l'utilisateur n'a pas d'abonnement pour ce dashboard -->
  <div class="alert_premium" v-if="code_access == 403">
    <p class="alert alert-warning text-center mt-3">You must be a <strong>premium</strong> member to view these charts.
      You can see the
      different subscription
      plans <a href="http://app.taceconomics.com/pricing">here</a>.</p>
  </div>

  <!-- Si le dashboard n'existe pas -->
  <div v-if="code_access == 404">
    <p class="alert alert-warning text-center mt-3">This dashboard does not exist, find all the dashboards <a
        href="http://app.taceconomics.com/dashboards">here</a>.</p>
  </div>
</template>
  

<script>
import api from '@/services/api'
import ChartLine from '@/components/Chart/ChartLine.vue'
import ChartLines from '@/components/Chart/ChartLines.vue'
import html2canvas from 'html2canvas';
import { mapGetters } from "vuex";
// import json from "./JSON/Country Risk.json"

export default {
  props: { id: { type: String, default: "1" } },
  components: {
    ChartLine, ChartLines
  },
  data() {
    return {
      dashboard: {},
      items: [],
      datasets: [],
      countries: [],
      checked: {
        countries: null,
      },
      searchTerm: '',
      selectedCountry: null,
      code_access: null,
      user: {
        email: "",
        firstname: "",
        lastname: "",
        company_name: "",
        api_key: "",
        subscriptions: []
      },
      firstElementType: null,
      year_map: null,
      country_selectedMap: null,
      isFirstSlide: false,
      isLastSlide: false,
    }
  },
  methods: {
    handleCountrySelectedChange(newCountrySelected) {
      this.country_selectedMap = newCountrySelected;
      this.checked.countries = this.country_selectedMap

      for (let i = 0; i < this.countries.length; i++) {
        if (this.checked.countries === this.countries[i].id) {
          this.selectedCountry = this.countries[i].short_name;
        }
      }
    },
    async load() {

      // Requête sur l'api en fonction de l'id du dashboard
      await api.get("/dashboards/" + this.id)
        .then(response => this.dashboard = response.data)
        .catch((error) => {
          this.code_access = error.response.status;
        });
      // this.dashboard = json;

      let codes = [];
      let liens = [];

      codes = this.dashboard.content[0].codes.split(",");
      codes.forEach(function (chaine) {
        let chaineDivisee = chaine.split(":");
        liens.push(chaineDivisee);
      });

      // Plusieurs pays dans un dashboard
      if (this.dashboard.multi_country) {
        try {
          const response = await api.get("/data/" + liens[0][0].split("/")[0]);

          // Construction du menu déroulant avec tous les pays disponibles pour ce dashboard
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].symbol == liens[0][0].split("/")[1]) {
              const countriesArray = response.data.data[i].country_list.split(",");
              const countriesList = countriesArray.map(country => {
                return { short_name: "", id: country };
              });

              const all_countries_response = await api.get("/data/countries");
              const all_countries = all_countries_response.data.data;

              for (let i = 0; i < countriesList.length; i++) {
                const country2 = countriesList[i];
                const country1 = all_countries.find(country => country.id === country2.id);
                if (country1) {
                  country2.short_name = country1.short_name;
                }
              }

              this.countries = countriesList;
            }
          }
        } catch (error) {
          console.error(error);
        }

        // Sélectionner dans le dashboard en premier, le graphique de type 'Map'
        let contentObj = this.dashboard.content;
        let firstElement = contentObj[0];
        this.firstElementType = firstElement.type

        // Année basée sur la carte du monde
        this.year_map = this.dashboard.content[0].start_date;
      }
    },
    onSearchInput() {
      // Fonction de recherche d'un pays en temps réel
      return this.countries.filter(country => {
        const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';
        const name = country.short_name.toLowerCase();
        return name.includes(search);
      });
    },
    onFilterChange() {
      // Change le pays pour modifier les graphiques
      for (let i = 0; i < this.countries.length; i++) {
        if (this.checked.countries === this.countries[i].id) {
          this.selectedCountry = this.countries[i].short_name;
        }
      }
    },
    download_dashboard() {
      const container = document.querySelector('.page_dashboard');
      const loading = document.querySelector(".loading_screenshot");

      container.classList.add('capture-mode');
      loading.classList.add('capture-mode_loading');

      setTimeout(() => {
        html2canvas(container).then(canvas => {
          container.classList.remove('capture-mode');

          const image = canvas.toDataURL('image/png');

          const link = document.createElement('a');
          link.href = image;
          link.download = this.dashboard.name + '.png';

          link.click();
          loading.classList.remove('capture-mode_loading');
        });
      }, 0.5);
    },
    toggleRadioButton(event) {
      // Permet de pouvoir désélectionner une option dans la liste des pays
      const selectedValue = event.target.value;
      if (this.checked.countries === selectedValue) {
        this.checked.countries = null;
        this.selectedCountry = null;
      }
    },
    changeYearMap(event) {
      // Récupère l'année qui a été sélectionné dans la liste des années
      this.year_map = event.target.value;
    }
  },
  computed: {
    ...mapGetters(["email"]),
    filteredCountries() {
      // Affichage des noms du pays au lieu de leurs codes
      if (this.countries) {
        return this.countries.filter(country => {
          return country.short_name;
        });
      } return 0;
    },
    withoutfirstElementType() {
      let res = null;
      if (this.dashboard.multi_country) {
        let contentObj2 = this.dashboard.content;
        let firstElement2 = contentObj2.slice(1);
        res = firstElement2;
      }
      return res;
    },
    groupedYears() {
      let groups = [];
      for (let i = 0; i < this.dashboard.content[0].years.length; i += 5) {
        groups.push(this.dashboard.content[0].years.slice(i, i + 5));
      }
      return groups;
    },
  },
  updated() {
    this.$nextTick(() => {
      var myCarousel = document.querySelector('#carouselOfYears')
      if (myCarousel) {
        myCarousel.addEventListener('slid.bs.carousel', (event) => {
          // Récupère l'année qui a été sélectionnée dans la liste des années
          const selectedYear = event.target.querySelector('.carousel-item.active button').value;
          this.changeYearMap({ target: { value: selectedYear } });
        });
      }
    });
  },
  mounted() {
    this.load();
  },
  created() {
    api.get("/user/me")
      .then(response => {
        this.user = response.data;
      })
  }
}
</script>

<style scoped>
.page_dashboard {
  padding: 0 9%;
}

.title_dashboard {
  position: sticky;
  top: 6em;
  padding: 1em;
  margin-top: 1em;
  background-color: white;
  box-shadow: 0 0 3px black;
  z-index: 6;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.capture-mode .title_dashboard {
  box-shadow: none !important;
  position: static !important;
  top: auto !important;
  background-color: transparent !important;
  border-radius: 0 !important;
}

.capture-mode .download_dashboard {
  display: none !important;
}

.dashboard_name {
  display: inline-flex;
}

.dropdown-menu {
  max-height: 400px;
  width: 250px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgb(153, 153, 153);
}

.dropdown button {
  border: solid 2px #021559;
  padding: 0.4em 0.8em;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  font-size: 1.1em;
  background-color: rgb(255, 255, 255);
}

.dropdown button:hover {
  background-color: rgb(229, 229, 229);
}

.dropdown button:focus {
  background-color: #021559;
  color: white;
}

.checkboxCountry label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.checkboxCountry input[type="radio"] {
  appearance: none;
  /* Désactiver l'apparence par défaut du navigateur */
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #021559;
  border-radius: 10px;
  margin-right: 5px;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}

.checkboxCountry input[type="radio"]:checked {
  background-color: #021559;
}

.checkboxCountry input[type="radio"]:focus {
  outline: none;
  /* Supprimer l'outline par défaut lorsqu'il est focus */
}

.checkboxCountry input[type="radio"]:checked::before {
  content: "";
  /* Afficher le symbole de coche Unicode */
  display: inline-block;
  width: 16px;
  height: 16px;
  /* line-height: 16px; */
  text-align: center;
  font-size: 12px;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.country-btn {
  padding: 0.5em 2em;
  font-size: 1em;
}

.country_selected {
  font-weight: 600;
}

.select_country {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.popup {
  z-index: 90;
  text-align: center;
  margin-right: 1em;
  color: white;
  padding: 0.5em 1em;
  background-color: rgb(90, 168, 90);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.popup:hover {
  box-shadow: 0 0 5px rgb(70, 131, 70);
}

.download_dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0.7em 0.9em;
  background-color: rgb(255, 255, 255);
  border: solid 2px #021559;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  width: max-content;
  margin: 0.5em 1em;
  width: 50px;
}

.download_dashboard:hover {
  background-color: #021559;
  color: white;
}

.download_dashboard:active {
  background-color: white;
  color: #021559;
}

.download_dashboard i {
  font-size: 1.2em;
  color: #021559;
}

.download_dashboard:hover i:before {
  content: "\f019";
  color: white;
}

.download_dashboard:active i:before {
  color: #021559;
}

.capture_loading {
  display: none;
}

.capture-mode_loading .capture_loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.677);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.screen_progress {
  font-size: 2.5em;
  color: white;
  margin: 0;
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  25%,
  75% {
    transform: translateY(-15px);
  }

  50% {
    transform: translateY(0);
  }
}

.alert_premium {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.275);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert_premium p {
  font-size: 1.3em;
  border-radius: 10px;
  box-shadow: 0 0 4px black;
}

.alert_premium a {
  text-decoration: underline;
}

.blurred-block {
  filter: blur(2px);
}

.buttons_disabled {
  font-size: 0.9em;
  margin: 1em;
  width: 38%;
}

.buttons_disabled p {
  text-align: right;
  margin: 0;
}

@media screen and (max-width:991px) {
  .title_dashboard {
    flex-direction: column;
  }

  .buttons_disabled {
    width: 100%;
  }

  .buttons_disabled p {
    text-align: center;
  }
}

.map_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btnYears_Map {
  height: 70px;
  border: solid 1px rgb(226, 226, 226);
  border-radius: 5px;
}

.btn_year {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.btn_year:hover {
  background-color: rgb(206, 206, 206);
}

.btn_year:active {
  background-color: rgb(188, 188, 188);
}

.btn_year.selected {
  /* Définissez ici la couleur souhaitée pour les boutons sélectionnés */
  background-color: rgb(45, 45, 45);
  color: white;
}

.carousel-control-prev,
.carousel-control-next{
  width: 35%;
}
</style>