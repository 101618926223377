<template>
  <div v-for="item in items" :key="item.symbol">
    <div @click.stop class="p-1 item" :class="'ps-'+level" v-if="item[props.risk] !== 0" >
      <input class="me-1" :class="'ms-'+level" type="radio" name="indicator" :id="item.symbol" v-model="selectedItem" :value="item.symbol" @click.stop="updateIndicator(item)"/>
      <label class="me-2" :for="item.symbol">{{ item.description }}</label>
      <i v-if="item.children && item.children.length > 0" @click.stop="toggleCollapse(item.id)" :class="{ 'fa-solid fa-chevron-down': !collapsed[item.id], 'fa-solid fa-chevron-right': collapsed[item.id] }"></i>
    </div>
    <div v-if="!collapsed[item.id] && item.children">
      <RecursiveMenuItem :items="item.children" :collapsed="collapsed" :selectedItem="selectedItem" :level="level+1" @update-indicator="updateIndicator" :risk="props.risk"/>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, ref, defineEmits } from 'vue';

const props = defineProps({
  items: Array,
  collapsed: Object,
  selectedItem: String,
  level: Number, 
  risk: String
});

const emit = defineEmits(['update-indicator']);
let items = reactive(props.items);
let collapsed = reactive(props.collapsed);
let selectedItem = ref(props.selectedItem);
let level = ref(props.level);
let openedItem = reactive({});

function toggleCollapse(itemId) {
  // Si l'élément sélectionné est déjà ouvert, le fermer
  if (openedItem[level.value] === itemId) {
    collapsed[itemId] = true;
    openedItem[level.value] = undefined;
  } else {
    // Si un autre élément est déjà ouvert à ce niveau, le fermer
    if (openedItem[level.value]) {
      collapsed[openedItem[level.value]] = true;
    }
    // Ouvrir l'élément sélectionné et le stocker comme l'élément ouvert à ce niveau
    collapsed[itemId] = false;
    openedItem[level.value] = itemId;
  }
}

function updateIndicator(item) {
  selectedItem.value = item.symbol;
  let indicator = { symbol: item.symbol, description: item.description };
  emit('update-indicator', indicator);
}

</script>

<style scoped>
div .item:hover{
  background-color: lightgray;
}

.item label:hover, .item input:hover, .item i:hover{
  cursor: pointer;
}

.fa-solid{
  cursor: pointer;
}

.item input[type="radio"] {
    display: none;
}

.item input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    position: relative;
    bottom: 5px;
    border-radius: 50%;
    border: 2px solid #021559;
    background-color: #fff;
    vertical-align: bottom;
}

.item input[type="radio"]:checked + label::before {
    background-color: #021559;
}

</style>