<template>
  <div class="d-flex align-items-center justify-content-center mt-5">
    <div class="mx-auto border rounded bg-white shadow mt-5 mb-5 p-5 login-form">
      <img class="logo mx-auto d-block" src="../assets/img/taceconomics.png">
      <div class="text-center txt-confirm">Thank you for completing your registration with TAC Economics, your account is
        activated.</div>
      <p class="text-center redirect">Redirect in {{ countdown }} seconds...</p>

      <div v-if="statusErr" class="alert alert-warning mt-4 text-center">Token expired, please try again to send a
        password change email.</div>
      <div class="text-center">Retry <a class="underline" href="/forgotpwd">here</a></div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api';
import Cookies from 'js-cookie';
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["email"]),
  },
  data() {
    return {
      duration: 5,
      countdown: 5,
      timer: null,
      statusErr: '',
    }
  },
  mounted() {
    // Vérification da la validité du token 
    api.post('/user/expireToken', {
      token: this.getToken()
    })
      .then(response => {
   
        // Validement du compte
        api.post('/user/verifyaccount', {
          "token": this.getToken()

        }).then(response => {
          console.log(response);
          Cookies.remove('email');
          Cookies.remove('password');
          Cookies.remove('password_repeat');
        })

        if (response.status == 400) {
          this.statusErr = response.status;
        }
        else {
          // Redirection automatique
          this.startCountdown()
        }
      })
      .catch((error) => {
        this.error = error.response.data.error;
      });
  },
  methods: {
    startCountdown() {
      this.timer = setInterval(() => {
        this.countdown = this.duration--
        if (this.countdown === 0) {
          this.stopCountdown()
          this.$router.push({ path: '/user' })
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer)
    },
    /**
     * Retourne le token à partir de l'url
     */
    getToken() {
      return this.$route.query.token;
    }
  },
  beforeRouteLeave() {
    if (this.countdown > 0) {
      this.stopCountdown()
    }
  }
};
</script>


<style scoped>
.logo {
  width: 5rem;
  height: auto;
  margin-top: -85px;
  margin-bottom: 20px;
}

.login-form {
  min-width: 400px;
}

.txt-confirm {
  font-size: 1.2em;
}

.redirect {
  margin: 1em 0 0 0;
}
</style>
