<template>
  <div class="blocChartline row mt-3">
    <div class="col" style="height: 700px">
      <ChartLine :dataset="dataset" :symbol="symbol" :params="params" />
    </div>
  </div>
</template>


<script>
import ChartLine from '@/components/Chart/ChartLine.vue'

export default {
  props: ["dataset", "symbol", "params"],
  components: {
    ChartLine,
  },
}
</script>

<style scoped>
.blocChartline {
  padding: 0 10%;
}
</style>