<template>
  <div class="d-flex align-items-center justify-content-center mt-5">
    <div class="mx-auto border rounded bg-white shadow mt-5 mb-5 p-5 login-form">
      <img class="logo mx-auto d-block" src="../assets/img/taceconomics.png">
      <form @submit.prevent="resend">
        <h2 class="pt-2 text-center mb-4">Mail Confirmation</h2>
        <div class="text-center text">A confirmation email has been sent to your address, please confirm your registration
          by clicking on the link in the email. If you have not received an email, maybe check your spam folder.</div>
        <button type="submit" class="mt-4 mb-4 btn btn-dark w-100" id="login_button">Resend</button>
      </form>
      <div class="text-center">If you have confirm your account, you can close this page</div>
    </div>
  </div>
</template>


<script>
import api from '@/services/api'
import Cookies from 'js-cookie';
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["email"]),
  },
  data() {
    return {
      error: '',
      user: {
        email: "",
        firstname: "",
        lastname: "",
        company_name: "",
        subscriptions: []
      },
    };
  },
  created() {
    api.get("/user/me")
      .then(response => {
        this.user = response.data;
      })
  },
  methods: {
    resend() {

      api.get("/user/me")

        .then(response => {

          this.user = response.data;

          api.post("/user/isvalidate", {
            'email': this.user.email
          })
            .then(response => this.validated = response.data.isvalidate)
            .catch((error) => {
              console.log(error.response);
            });

        })

      let email2send;
      // Si l'utilisateur est déjà connecté
      if (Cookies.get('mail')) {
        email2send = Cookies.get('mail')
      } else {
        email2send = this.user.email;
      }

      // Renvoi d'un mail de confirmation
      api.post('/user/resendverify', {
        'email': email2send,
      })
        .then(response => {

          this.response = response.data;

          this.token = this.response.usertoken;

          this.$store.dispatch('email', this.email);
          this.$store.dispatch('token', this.response.usertoken);
          this.$store.dispatch('refresh_token', this.response.refresh_token);

          setTimeout(() => this.$router.push({ path: '/signupmail' }), 5000);
        })
        .catch((error) => {
          this.error = error.response.data.error;
        });

    },
  }
};
</script>


<style scoped>
.logo {
  width: 5rem;
  height: auto;
  margin-top: -85px;
  margin-bottom: 20px;
}

.login-form,
.text {
  max-width: 400px;
}

.pointer {
  cursor: pointer;
}
</style>
