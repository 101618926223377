<template>
  <div class="border bg-white">
    <div id="chartdiv" style="width: 100%; height: 500px;"></div>
  </div>
</template>

<script setup>
/* eslint-disable */
  import { onMounted, ref, defineProps, watch, defineEmits } from 'vue';
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4maps from "@amcharts/amcharts4/maps";
  import TacEconomics from "../../assets/img/taclogo.png";
  import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
  import { countryNames } from "../../utils/CountryMapping";
  import api from '@/services/api'

  const props = defineProps({
    item: {
      type: String,
      required: true,
    },
  });

  const selectedItem = ref(props.item);

  watch(
    () => props.item,
    (newVal) => {
      selectedItem.value = newVal;
      updateMap(selectedItem.value.toLowerCase());
    }
  );

  const fetchedData = ref([]);
  const polygonSeries = ref(null);

  async function fetchData() {
      await api.get("/data/loadviews/countryrisk/ALLCNT")
        .then(response => fetchedData.value = response.data.data.map)
        .catch((error) => {
          console.error(error);
        });
  }

  function updateMap(selectedItem) {
    if (fetchedData.value.length > 0) {
  
          let dataToDisplay;
          if (selectedItem === "country risk premium") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      iso3: country.country_id,
                      value: country["crp"]
                  };
              });
          } else if (selectedItem === "economic risk rating") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      iso3: country.country_id,
                      value: country["ratingeco"]
                  };
              });
        } else if (selectedItem === "political risk rating") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      iso3: country.country_id,
                      value: country["ratingpol"]
                  };
              });
        } else {
                  dataToDisplay = fetchedData.value.map(country => {
                      return {
                          iso2: country.iso2,
                          iso3: country.country_id,
                          value: country[selectedItem]
                      };
                  });
          }
  
      dataToDisplay.forEach(data => {
        const countryPolygon = polygonSeries.value.getPolygonById(data.iso2);
        if (countryPolygon) {
          // console.log(selectedItem)
          if((selectedItem==="crp") | (selectedItem==="country risk premium")) {
            countryPolygon.fill = getProgressColorCRP(data.value);
          } else {
            countryPolygon.fill = getProgressColor(data.value);
          }
          countryPolygon.tooltipText = `${countryNames[data.iso2]} (${data.iso3}): ${data.value}`;
        }
      });
      
    } else {
      console.warn("Aucune donnée à afficher sur la carte.");
    }
  }

  function getProgressColor(value) {
    if (value >= 60) {
      return '#a82418';
    } else if (value >= 50) {
      return '#f2cacb';
    } else if (value >= 40) {
      return '#faf1d2';
    } else if (value >= 30) {
      return '#85cdf7';
    } else {
      return '#004085';
    }
  }

  function getProgressColorCRP(value) {
    if (value > 750) {
      return '#a82418';
    } else if (value > 600) {
      return '#f2cacb';
    } else if (value > 450) {
      return '#faf1d2';
    } else if (value > 300) {
      return '#85cdf7';
    } else {
      return '#004085';
    }
  }

  const emit = defineEmits(["countryClicked"]);

onMounted(async () => {
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Miller();

    polygonSeries.value = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.value.useGeodata = true;
    polygonSeries.value.exclude = ["AQ"];
    polygonSeries.value.mapPolygons.template.tooltipText = "{name}: {value}";

    polygonSeries.value.mapPolygons.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    
    polygonSeries.value.mapPolygons.template.events.on("hit", function(ev) {
        const isoCode = ev.target.dataItem.dataContext.id;
        emit("countryClicked", isoCode);
    });

    const imageUrl = TacEconomics
    const image = chart.createChild(am4core.Image);
    image.href = imageUrl;
    image.width = 50;
    image.height = 50;

    image.dx = 20;
    image.dy = 20;

    const latitude = 20;
    const longitude = 30;

    const point = chart.projection.rotate({ longitude: longitude, latitude: latitude });
    image.left = point.x;
    image.top = point.y;

    var legend = new am4maps.Legend();
    legend.parent = chart.chartContainer;
    legend.background.fillOpacity = 0.05;
    legend.width = 275;

    legend.align = "left";
    legend.valign = "bottom";

    legend.dx = 10;
    legend.dy = 350;

    legend.layout = "vertical";

    legend.itemContainers.template.marginTop = 5;
    legend.itemContainers.template.marginBottom = 5;

    legend.labels.template.fontSize = "12px";
    legend.labels.template.fill = am4core.color("#666666");

    legend.padding(10, 15, chart.chartContainer.maxHeight, 15);
    legend.data = [{
    "name": "Very Low Sensitivity",
    "fill":"#004085"
    }, {
    "name": "Low Sensitivity",
    "fill":"#85cdf7"
    }, {
    "name": "Medium Sensitivity",
    "fill":"#faf1d2"
    }, {
    "name": "High Sensitivity",
    "fill": "#f2cacb"
    }, {
    "name": "Very High Sensitivity",
    "fill": "#a82418"
    }];
    legend.data.reverse();
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;

    legend.itemContainers.template.padding(-9, 0, 0, 0);

    await fetchData();
    updateMap("crp");

    if (fetchedData.value.length > 0) {
      console.log(fetchedData.value);
      console.log(selectedItem.value);
      chart.exporting.adapter.add("data", (data) => {
        data.data = [];
        for (let row of fetchedData.value) {
          if (selectedItem.value === "country risk premium") {
            data.data.push({
              Country: countryNames[row.iso2],
              "Country Risk Premium": row.crp,
            });
          } else if (selectedItem.value === "economic risk rating") {
            data.data.push({
              Country: countryNames[row.iso2],
              "Economic Risk Rating": row.ratingeco,
            });
          } else if (selectedItem.value === "political risk rating") {
            data.data.push({
              Country: countryNames[row.iso2],
              "Political Risk Rating": row.ratingpol
            });
          }
        }
        return data;
      }); 
    }
    chart.exporting.filePrefix = "RiskMonitorMapExport";
    chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = "right";
      chart.exporting.menu.verticalAlign = "top";

      chart.exporting.menu.items = [{
        "label": "...",
        "menu": [
          { "type": "png", "label": "PNG" },
          { "type": "csv", "label": "CSV" }
        ],
      }];
});
</script>

<style scoped>
</style>