<template>
    <div id="chartdiv" style="width: 100%; height: 500px;"></div>
</template>

<script setup>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import TacEconomics from "../../assets/img/taclogo.png";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { ref, onMounted, defineProps, watch, defineEmits } from "vue";
import { countries, countryNames } from "../../utils/CountryMapping";

const polygonSeries = ref(null);
const selectedCountry = ref(null);
const ItemSelected = ref("GeoPolitical Sensitivity Index");
const isOpen = ref(false);

const props = defineProps({
    data: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    ItemSelected: {
      type: String,
      required: true,
    },
    fetchData: {
      type: Array,
      required: true,
    },
});

const fetchedData = ref(props.fetchData);

watch(() => props.fetchData, (newValue) => {
  console.log("fetchData updated:", newValue);
  fetchedData.value = newValue;
  updateMap(props.ItemSelected.toLowerCase());
});

const items = ref(props.items);

function getProgressColor(value) {
    if (value > 60) {
        return '#a82418';
    } else if (value > 55) {
        return '#c23728';
    } else if (value > 50) {
        return '#e48b87';
    } else if (value > 45) {
        return '#f2cacb';
    } else if (value > 40) {
        return '#faf1d2';
    } else if (value > 35) {
        return '#b2daf5';
    } else if (value > 30) {
        return '#85cdf7';
    } else if (value > 25) {
        return '#4f99cc';
    } else {
        return '#004085';
    }

}

  function updateMap(selectedItem) {
    if (fetchedData.value.length > 0) {
  
          let dataToDisplay;
          if (selectedItem === "current conflicts & sanctions") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      iso3: country.country_id,
                      value: country["cur_conf_sanct"]
                  };
              });
          } else if (selectedItem === "critical locks & knots") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      iso3: country.country_id,
                      value: country["crit_lock_knots"]
                  };
              });
        } else if (selectedItem === "geopolitical sensitivity index") {
                  dataToDisplay = fetchedData.value.map(country => {
                  return {
                      iso2: country.iso2,
                      iso3: country.country_id,
                      value: country["gpsi"]
                  };
              });
        } else {
                  dataToDisplay = fetchedData.value.map(country => {
                      return {
                          iso2: country.iso2,
                          iso3: country.country_id,
                          value: country[selectedItem]
                      };
                  });
          }
  
      dataToDisplay.forEach(data => {
        const countryPolygon = polygonSeries.value.getPolygonById(data.iso2);
        if (countryPolygon) {
          countryPolygon.fill = getProgressColor(data.value);
          // selectedCountry = countries.value.find(c => c.code === data.iso2).code3.value
          countryPolygon.tooltipText = `${countryNames[data.iso2]} (${data.iso3}) : ${data.value.toPrecision(3)}`;
        }
      });
    } else {
      console.warn("Aucune donnée à afficher sur la carte.");
    }
  }

  const emit = defineEmits(['update-indicator']);

  function updateIndicator(selectedCountry, bool) {
    console.log(selectedCountry, bool);
    emit('update-indicator', selectedCountry, bool);
  }

  onMounted(async () => {
    countries.value = props.data;
    console.log(props.data)
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Miller();

    polygonSeries.value = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.value.useGeodata = true;
    polygonSeries.value.exclude = ["AQ"];
    polygonSeries.value.mapPolygons.template.tooltipText = "{name}: {value}";

    polygonSeries.value.mapPolygons.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    
    polygonSeries.value.mapPolygons.template.events.on("hit", function(ev) {
        const isoCode = ev.target.dataItem.dataContext.id;
        console.log(countries);
        selectedCountry.value = props.data.find(c => c.code === isoCode).code3;
        isOpen.value = true;
        updateIndicator(selectedCountry.value, isOpen.value);
    });

    const imageUrl = TacEconomics
    const image = chart.createChild(am4core.Image);
    image.href = imageUrl;
    image.width = 50;
    image.height = 50;

    image.dx = 20;
    image.dy = 20;

    const latitude = 20;
    const longitude = 30;

    const point = chart.projection.rotate({ longitude: longitude, latitude: latitude });
    image.left = point.x;
    image.top = point.y;

    var legend = new am4maps.Legend();
    legend.useDefaultMarker = true;
    let marker = legend.markers.template.children.getIndex(0);
    marker.cornerRadius(0, 0, 0, 0);
    legend.parent = chart.chartContainer;
    legend.background.fillOpacity = 0.05;
    legend.width = 275;

    legend.align = "left";
    legend.valign = "bottom";

    legend.dx = -40;
    legend.dy = 260;
  
    legend.layout = "vertical";

    legend.itemContainers.template.marginTop = 5;
    legend.itemContainers.template.marginBottom = 5;

    legend.labels.template.fontSize = "12px";
    legend.labels.template.fill = am4core.color("#666666");

    legend.padding(10, 15, chart.chartContainer.maxHeight, 15);
    legend.data = [{
      "name": "Very Low Sensitivity",
      "fill":"#004085"
    }, {
      "name": "",
      "fill":"#4f99cc"
    }, {
      "name": "Low Sensitivity",
      "fill":"#85cdf7"
    }, {
      "name": "",
      "fill":"#b2daf5"
    }, {
      "name": "Medium Sensitivity",
      "fill":"#faf1d2"
    }, {
      "name": "",
      "fill":"#f2cacb"
    }, {
      "name": "High Sensitivity",
      "fill": "#e48b87"
    }, {
      "name": "",
      "fill": "#c23728"
    }, {
      "name": "Very High Sensitivity",
      "fill": "#a82418"
    }];
    legend.data.reverse();
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;

    legend.itemContainers.template.padding(-10, 0, 0, 0);

    const defaultItem = items.value[0];
    updateMap(defaultItem.text.toLowerCase());

    if (fetchedData.value.length > 0) {
      chart.exporting.adapter.add("data", function(data) {
        data.data = [];
        for (let row of fetchedData.value) {
          if (ItemSelected.value === "GeoPolitical Sensitivity Index")
            data.data.push({
              Country: countryNames[row.iso2],
              'GeoPolitical Sensitivity Index': row.gpsi
            });
          else if (ItemSelected.value === "Alliances")
            data.data.push({
              Country: countryNames[row.iso2],
              'Alliances': row.alliances
            });
          else if (ItemSelected.value === "Current Conflicts & Sanctions")
            data.data.push({
              Country: countryNames[row.iso2],
              'Current Conflicts & Sanctions': row.cur_conf_sanct
            });
          else if (ItemSelected.value === "Critical Locks & Knots")
            data.data.push({
              Country: countryNames[row.iso2],
              'Critical Locks & Knots': row.crit_lock_knots
            });
        }
        return data;
      });
      chart.exporting.filePrefix = "GeoPolMapExport";
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = "right";
      chart.exporting.menu.verticalAlign = "top";

      chart.exporting.menu.items = [{
        "label": "...",
        "menu": [
          { "type": "png", "label": "PNG" },
          { "type": "csv", "label": "CSV" }
        ]
      }];
    }
});
</script>

<style scoped>
</style>