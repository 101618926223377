<template>
    <div class="pb-4">
        <h2 class="pt-3"><i class="fa-solid fa-book-atlas me-3"></i>Methodology</h2>
        <hr />
        <h2 class="mb-3">GPSI - Geopolitical Sensitivity Index</h2>
        <p>
            The <strong>Geopolitical Sensitivity Index (GPSI)</strong> is a comprehensive quantitative tool developed by TAC ECONOMICS 
            designed to assess the geopolitical vulnerability of countries based on a range of critical indicators. It evaluates a 
            country's position across three key dimensions: <strong> alliances, current conflicts & sanctions</strong>, and <strong>critical locks & knots</strong>. 
            The index considers alliance consistency, geographical factors, and anti-Western polarization. It also examines ongoing 
            conflicts and international sanctions imposed by the <strong>UN</strong>, <strong>US</strong>, and <strong>EU</strong>. In addition, the GPSI analyzes technological 
            dominance, the production of critical resources like energy and uranium, and the strategic importance of global networks 
            such as shipping routes, submarine cables, satellites, and pipelines. This multifaceted approach offers a valuable 
            measure of geopolitical risk, providing deeper insights into the geopolitical country’s vulnerability.
        </p>
        <div class="container mt-4">
            <div class="row gy-5 gy-lg-0 gx-xl-5">
            <div class="d-flex justify-content-center p-3" style="background-color: #13215c;">
                <h2 class="text-white">GPSI – GeoPolitical Sensitivity Index</h2>
            </div>
            <div class="col-12 col-lg-4 m-0 p-0">
                <div class="card border-0 shadow-sm pb-md-4 bsb-pricing-popular">
                <div class="card-body p-4 p-xxl-5">
                    <div class="d-flex justify-content-center mb-4">
                        <i class="fa-solid fa-handshake fa-2xl"></i>
                    </div>
                    <h2 class="h4 mb-2 text-center">Alliances</h2>
                    <ul class="list-group list-group-flush mb-4">
                    <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Alliance Consistency</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Military suppliers</p>
                                <p class="m-0">- Exports Markets</p>
                                <p class="m-0">- External creditors</p>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Geographies</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Tensions with USA</p>
                                <p class="m-0">- Tensions with China</p>
                                <p class="m-0">- Tensions with 2nd order powers countries</p>
                                <p class="m-0">- Maximum tensions with 2nd order powers countries</p>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Anti-Western Polarization</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Geopolitical distance with USA</p>
                                <p class="m-0">- Diplomatic polarization</p>
                                <p class="m-0">- AWP from IPD, military supply, markets and debt</p>
                                <p class="m-0">- Military bases and defense agreements</p>
                            </li>
                        </ul>
                    </li>
                    </ul>
                </div>
                </div>
            </div> 
            <div class="col-12 col-lg-4 m-0 p-0">
                <div class="card border-0 shadow-sm pb-md-4 bsb-pricing-popular">
                <div class="card-body p-4 p-xxl-5">
                    <div class="d-flex justify-content-center mb-4">
                        <i class="fa-solid fa-triangle-exclamation fa-2xl"></i>
                    </div>
                    <h2 class="h4 text-center">Current Conflicts & Sanctions</h2>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Current Conflicts</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Crisis intensity and trend over the last 3 months</p>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Geopolitical Buzz</strong></span>
                    </li>
                    <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Military Buildup</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Military expenditure (% GDP)</p>
                                <p class="m-0">- Military expenditure growth</p>
                            </li>
                        </ul>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 m-0 p-0">
                <div class="card border-0 shadow-sm">
                <div class="card-body p-4 p-xxl-5">
                    <div class="d-flex justify-content-center mb-4">
                        <i class="fa-brands fa-joomla fa-2xl"></i>
                    </div>
                    <h2 class="h4 mb-2 text-center">Critical Locks & Knots</h2>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Technology</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Overall technology strength</p>
                                <p class="m-0">- Technology dominance in selected areas</p>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Resources</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Critical Resources production</p>
                                <p class="m-0">- Energy production</p>
                                <p class="m-0">- Uranium production</p>
                            </li>
                        </ul>
                    </li>
                    <li class="list-group-item">
                        <i class="fa-regular fa-circle me-2"></i>
                        <span><strong>Networks</strong></span>
                        <ul>
                            <li class="mt-2" style="list-style-type: none;">
                                <p class="m-0">- Shipping chokepoints</p>
                                <p class="m-0">- Submarine cables</p>
                                <p class="m-0">- Satellites</p>
                                <p class="m-0">- pipelines</p>
                            </li>
                        </ul>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
            <h2 class="mb-3 mt-5">Alert GPT – Alert on GeoPolitical Tensions</h2>
            <p>The <strong>Alert on Geopolitical Tensions</strong> is a tool developed by TAC ECONOMICS designed to monitor geopolitical tensions based on daily news articles. It is constructed using three key components:</p>
            <div class="ms-4">
                <i class="fa-regular fa-circle me-3"></i>
                <span><strong>Buzz:</strong> This represents the total number of articles related to geopolitical events. It serves as a measure of media attention or volume of coverage on a particular geopolitics topic.</span>
                <br><i class="fa-regular fa-circle me-3 mt-4"></i>
                <span><strong>Intensity:</strong> This reflects the number of negative articles within the total Buzz. It captures the proportion of news that reports on events or situations in a negative light, indicating growing concern or instability.</span>
                <br><i class="fa-regular fa-circle me-3 mt-4"></i>
                <span><strong>Violence:</strong> This measures the number of articles from the Buzz that use strong language or describe particularly violent scenarios. It highlights instances where the discourse shifts towards describing a highly volatile or violent geopolitical environment.</span>
            </div>
            <br>
            <p class="mt-4">Together, these three indicators provide a timely and nuanced picture of geopolitical tensions by leveraging media reporting, offering a quantitative alert system to track emerging risks or crises in real-time.</p>
    </div>
</template>

<script setup>

</script>
