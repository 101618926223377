<template>
  <div v-if="infos_chart" class="opacity_background"></div>
  <div v-if="infos_chart" class="infos_chart fade-in">
    <div class="title_infos">
      <h2>{{ series.name }}</h2>
      <h3>{{ series.unit }}</h3>
    </div>
    <table>
      <thead>
        <tr>
          <th>Datalab Code</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <a :href="'https://app.taceconomics.com/data/' + series.id" target="_blank"><span>{{ series.id }}</span></a>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="buttons_infos">

      <button @click="downloadPDF()">PDF (graph) <i class="fa-solid fa-file-pdf"></i></button>
      <button @click="downloadPNG()">PNG (graph) <i class="fa-solid fa-file-image"></i></button>
      <button @click="exportXLSX()">Excel (data) <i class="fa-solid fa-file-excel"></i></button>
      <button @click="exportCSV()">CSV (data) <i class="fa-solid fa-file-csv"></i></button>

    </div>

    <div class="menu-burger" @click="close_infos_chart">
      <i class="fa-solid fa-xmark"></i>
    </div>
  </div>


  <div class="row p-2 mt-3" @mousemove="updateMessagePosition">

    <div class="row p-2 mt-3" v-bind:class="{ 'blurred-block': code_access == 403 }">
      <!-- ############ Légende ############ -->
      <div class="col-md-3" style="height: max-content">
        <div class="card description p-4 bg-light">
          <div v-if=true class="mb-3">
            <small><b>Dataset</b><br>{{ series.dataset_name}}</small>
          </div>
          <div class="mb-3"><small><b>Premium / Free</b><br>
              <div v-if="series.free == true" class="badge badge-free">free</div>
              <div v-else class="badge badge-premium">premium</div>
            </small></div>

          <div class="mb-2">
            <small>
              <div class="api_code_copy mb-2">
                <b class="me-1">API CODE</b><i class="fa-regular fa-copy" @click="copyContent" title="Copy code"></i>
              </div>
              <a class="text-uppercase">
                <span class="api_code">{{ series.id }}</span>
              </a>
            </small>
          </div>

          <div v-if="isMessageCopyVisible" class="api_code_message" :style="{ top: mouseY + 'px', left: mouseX + 'px' }">
            Copied Code !
          </div>

          
          <div class="mb-3" v-if=true><small><b>Name</b><br>{{ series.name }} ({{
            series.unit }})</small></div>
          <div class="mb-3" v-else>
            <small><b>Name</b><br>Unavailable</small>
          </div>


          <div class="mb-3" v-if=true><small><b>Unit</b><br>{{ series.unit }}</small>
          </div>
          <div class="mb-3" v-else>
            <small><b>Unit</b><br>Unavailable</small>
          </div>

          <div class="mb-3" v-if=true><small><b>Description</b><br>{{ series.description
          }}</small></div>
          <div class="mb-3" v-else>
            <small><b>Description</b><br>Unavailable</small>
          </div>

        </div>

      </div>

      <!-- ############ Graphique ############ -->
      <div id="chart_block" class="chart_bloc col" style="height: 700px" v-if="code_access != 403">


        <div class="card p-4 h-100 png_pdf">

          <div class="card p-4 h-100">
            <div class="d-flex flex-wrap w-100 align-items-center justify-content-between info_values rounded bg-light">
              <div class="ps-2 col-12" v-if=true>
                <small><b>Dataset : </b>{{ series.dataset_name}}</small>
              </div>
              <div class="ps-2 col-12" v-if="this.lastDataItem != null">
                <small><b>Last value : </b>{{ this.lastDataItem.value.toFixed(2) }}</small>
              </div>
              <div class="ps-2 col-12" v-if="this.lastDataItem != null">
                <small><b>Last date : </b>{{ this.lastDataItem.timestamp }}</small>
              </div>
            </div>

            <div class="d-flex flex-wrap align-items-center my-3" >
              <a href="https://app.taceconomics.com/" class="col-1 ps-2">
                <img src="../../assets/img/taclogo.png" alt="" width="60">
              </a>
              <div class="col-10">
                <h2 class="titleChartline mb-0 text-center col-12">{{ series.name }} - {{ series.country_short_name }}</h2><nav id="titleChartline"></nav>
                <h3 class="text-center col-12 mb-0"><span v-if="series.unit">{{ series.unit }}</span></h3>
                <div @click="display_infos_chart" class="menu-button close close2">
                  <i class="fa-solid fa-bars"></i>
                </div>
              </div>
            </div>

            <div class="chart" ref="chartdiv"></div>
            <div class="w-100 text-end my-2" v-if=series.provider_name>
                <small><b>Source : </b>{{ series.provider_name}}</small>
              </div>
          </div>

          <div class="filters d-flex justify-content-between m-3 gap-3 raw">
              <div class="col-3" v-if=true><small><b>Frequency</b><br></small>
              <select v-model="selectedOptionCollapse" @change="change_collapse()">
                <option v-for="option, index in optionsCollapse" :key=index :value="option.value"
                  :selected="selectedOptionCollapse === option.value">{{ option.label }}</option>
              </select>
              </div>


            <div class="col-3" v-if=true><small><b>Aggregation mod</b><br></small>
              <select v-model="selectedOptionModeCollapse" @change="change_mode_collapse()" :disabled="!isFrequencyChanged" >
                <option v-for="option, index in optionsModCollapse" :key=index :value="option.value"
                  :selected="selectedOptionModeCollapse === option.value">{{ option.label }}</option>
              </select>
            </div>


            <div class="col-4" v-if=true><small><b>Transformation</b><br></small>
              <select v-model="selectedOptionTransform" @change="change_transform()">
                <option v-for="option, index in optionsTransform" :key=index :value="option.value"
                  :selected="selectedOptionTransform === option.value">{{ option.label }}</option>
              </select>
            </div>
        </div>
      </div>
      </div>
    </div>
    <div class="alert_premium" v-if="code_access == 403">
      <p class="alert alert-warning text-center mt-3">You must be a <strong>premium</strong> member to view this chart.
        You can see the
        different subscription
        plans <a href="http://app.taceconomics.com/pricing">here</a>.</p>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { mapGetters } from "vuex";

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import * as d3 from 'd3-dsv';

am4core.useTheme(am4themes_animated);

// https://stackoverflow.com/questions/57572182/making-am4charts-reactive-in-vuejs

export default {
  props: { dataset: String, symbol: String, params: String, transform: String, start_date: { type: String, default: null }, color: { type: String, default: "#CC3517" } },
  data() {
    return {
      series: {},
      chart: '',
      selectedOptionCollapse: '',
      selectedOptionModeCollapse: '',
      selectedOptionTransform: '',
      optionsCollapse: [
        { value: 'A', label: 'Annual' },
        { value: 'Q', label: 'Quarterly' },
        { value: 'D', label: 'Daily' },
        { value: 'M', label: 'Monthly' },
      ],
      optionsModCollapse: [
        { value: 'start_of_period', label: 'Start of Period' },
        { value: 'end_of_period', label: 'End of Period' },
        { value: 'median', label: 'Median' },
        { value: 'mean', label: 'Mean' },
      ],
      optionsTransform: [
        { value: 'null', label: 'No Transform' },
        { value: 'diff', label: 'Difference since last period' },
        { value: 'diff_yoy', label: 'Difference since last year' },
        { value: 'growth', label: 'Growth Rate since last period' },
        { value: 'growth_yoy', label: 'Growth Rate since last year' },
      ],
      code_access: null,
      user: {
        email: "",
        firstname: "",
        lastname: "",
        company_name: "",
        api_key: "",
        subscriptions: []
      },
      showPopup: true,
      api_code: "",
      originalContent: "",
      copiedContent: "",
      isMessageCopyVisible: false,
      messageItem: null,
      baseFrequency: null,
      isFrequencyChanged: false,
      lastDataItem: null,
      infos_chart: false,
      date: "",
    }
  },
  computed: {
      currentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Les mois commencent à 0 en JavaScript
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}:${month}:${day}`;
    },
    ...mapGetters(["email"]),
  },
  methods: {
    loadChart() {
      console.log('Values have changed');
      this.chart.invalidateRawData();
    },
    showMessage() {
      this.isMessageCopyVisible = true;
      this.updateMessagePosition(event);
      setTimeout(() => {
        this.isMessageCopyVisible = false;
      }, 2000);
    },
    updateMessagePosition(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    /* ### Copie le code du Datalab en 1 clique ### */
    copyContent() {
      this.showMessage();

      // Exemple: Utilisation de la méthode execCommand pour copier le contenu
      const textarea = document.createElement('textarea');
      textarea.value = this.series.id;
      console.log(this.copiedContent)
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },
    async renderChart(param1, param2, param3) {
      /* ############## CONSTRUCTION DE L'URL ############## */
      let url = "/data/" + this.dataset + "/" + this.symbol;
      this.copiedContent = url;

      if (this.params) url += "/" + this.params
      if (this.start_date) url += "?start_date=" + this.start_date
      if (this.transform) url += "&" + this.transform

      if (this.start_date) {
        url += "&collapse=" + param1 + "&mode_collapse=" + param2;
      } else {
        url += "?collapse=" + param1 + "&mode_collapse=" + param2;
      }
      
      this.copiedContent += "?collapse=" + param1;
      if(param2 != "mean"){
        this.copiedContent += "&mode_collapse=" + param2;
      }
      console.log(param3)
      if(param3 != null && param3 != "null"){
        this.copiedContent += "&transform=" + param3;
      }
      this.copiedContent = this.copiedContent.replace("/data/", "")

      url += "&transform=" + param3;

      /* ############## REQUETE SUR L'API ############## */
      await api.get(url)
        .then(response => this.series = response.data)
        .catch((error) => {
          this.code_access = error.response.status;
        });
      /* ############## SPECIFICATION DU GRAPHIQUE ############## */

      if (this.code_access != 401) {
        let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

        chart.responsive.enabled = true;

        chart.hiddenState.properties.opacity = 0;

        chart.data = this.series.data;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.tooltipDateFormat = "yyyy-MM-dd";

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "timestamp";
        series.dataFields.valueY = "value";
        series.fill = am4core.color(this.color);
        series.stroke = am4core.color(this.color);
        series.strokeWidth = 3;

        // var series = chart.series.push(new am4charts.ColumnSeries());
        // series.dataFields.valueY = "value";
        // series.dataFields.categoryX = "timestamp";
        //series.columns.template.tooltipText = "{from} - {to}\n[bold]Count: {count}[/]";
        
        // Tooltip normal pour le survol
        series.tooltipText = "{valueY.value}";
        chart.cursor = new am4charts.XYCursor();

        this.lastDataItem = chart.data[chart.data.length - 1];

        this.chart = chart;
      }
    },
    downloadPNG() {
      let block = document.getElementsByClassName('info_values');
      block[0].classList.add('d-none');

      // Télécharge l'image du graphique en PNG
      html2canvas(document.querySelector('.png_pdf')).then((canvas) => {

        const newCanvas = document.createElement('canvas');
        const newCtx = newCanvas.getContext('2d');
        const width = canvas.width;
        const height = canvas.height - 120;
        newCanvas.width = width;
        newCanvas.height = height;

        newCtx.drawImage(canvas, 0, 0, width, height, 0, 0, width, height);

        const link = document.createElement('a');
        link.href = newCanvas.toDataURL('image/png');
        link.download = 'TACECONOMICS_'+this.series.dataset_name+'_'+this.date+'.png';
        link.click();
      });

      block[0].classList.remove('d-none');
    },
    downloadPDF() {
      let block = document.getElementsByClassName('info_values');
      block[0].classList.add('d-none');

      // Télécharge l'image du graphique en PDF
      html2canvas(document.querySelector('.png_pdf')).then((canvas) => {

        var bloc = document.querySelector('.png_pdf');

        const cropX = 0;
        const cropY = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height - 120;

        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;
        const croppedCtx = croppedCanvas.getContext('2d');

        croppedCtx.drawImage(canvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

        const imgData = croppedCanvas.toDataURL('image/png');

        var pdf;

        if (bloc.offsetHeight >= bloc.offsetWidth) {
          pdf = new jsPDF()
        } else {
          pdf = new jsPDF({ orientation: 'landscape' })
        }

        var imgWidth = pdf.internal.pageSize.getWidth();
        var imgHeight = pdf.internal.pageSize.getHeight() - 10;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        pdf.save('TACECONOMICS_'+this.series.dataset_name+'_'+this.date+'.pdf');
      });

      block[0].classList.remove('d-none');
    },
    exportCSV() {
      // Télécharge les données du graphique en CSV
      const data = this.chart.data;

      // Remplacer l'en-tête "value" par "Autre libellé"
      const csvData = data.map(item => ({
        timestamp: item.timestamp,
        [this.series.id]: item.value
      }));

      const csv = d3.csvFormat(csvData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, 'TACECONOMICS_'+this.series.dataset_name+'_'+this.date+'.csv');
    },
    exportXLSX() {
      // Télécharge les données du graphique en XLSX
      const data = this.chart.data;

      // Remplacer l'en-tête "value" par "Autre libellé"
      const modifiedData = data.map(item => ({
        timestamp: item.timestamp,
        [this.series.id]: item.value
      }));

      const workbook = XLSX.utils.book_new();
      const sheet = XLSX.utils.json_to_sheet(modifiedData, { header: ["timestamp"] });
      XLSX.utils.book_append_sheet(workbook, sheet, 'Chart Data');
      const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(blob, 'TACECONOMICS_'+this.series.dataset_name+'_'+this.date+'.xlsx');
    },
    async load() {
      // Initialise les différents champs en fonction du graphique (collapse, mode collapse, etc.) 
      let url = "/data/" + this.dataset + "/" + this.symbol;
      if (this.params) url += "/" + this.params + "?transform=diff";
      await api.get(url).then(response => this.series = response.data)
      this.baseFrequency = this.series.frequency; 
      this.selectedOptionCollapse = this.series.frequency;
      this.selectedOptionModeCollapse = "mean";
      this.selectedOptionTransform = "null";

      this.renderChart(this.series.frequency, "mean", null);
    },

    /* ### Change le collapse, le mode collapse ou le transform en conservant les 2 autres choix ### */
    change_collapse() {
      if(this.selectedOptionCollapse == this.baseFrequency){
        this.isFrequencyChanged = false;
      }else{
        this.isFrequencyChanged = true;
      }
      this.renderChart(this.selectedOptionCollapse, this.selectedOptionModeCollapse, this.selectedOptionTransform);
    },
    change_mode_collapse() {
      this.renderChart(this.selectedOptionCollapse, this.selectedOptionModeCollapse, this.selectedOptionTransform);
    },
    change_transform() {
      this.renderChart(this.selectedOptionCollapse, this.selectedOptionModeCollapse, this.selectedOptionTransform);
    },
    display_infos_chart() {
      this.infos_chart = true;
    },
    close_infos_chart() {
      this.infos_chart = false;
    },
    handleKeyDown(event) {
      // Si on appuie sur la touche Escape, cela ferme la popup d'informations d'un graphique
      if (event.key === "Escape") {
        this.close_infos_chart();
      }
    },
  },
  mounted() {
    // Initialisation de la page
    this.date=this.currentDate;
    this.load();
    document.documentElement.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUmount() {
    if (this.chart) {
      this.chart.dispose();
      document.documentElement.addEventListener("keydown", this.handleKeyDown);
    }
  },
  created() {
    api.get("/user/me")
      .then(response => {
        this.user = response.data;
      })
  },
}


</script>


<style scoped>
.chart {
  width: 100%;
  height: 100%;
  /* height: 450px; */
}

.description{
  height: 700px;
}

.value::before{
  content: ''; 
  position: absolute; 
  top: 6px; 
  left: -8px; 
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; 
  border-right: 8px solid #CC3517; 
}

.value{
  top: 45%;
  right: 85px;
  width: 135px;
  background-color: #CC3517;
  color: white;
  border: 1px solid white;
  border-left: none;
  box-shadow: 2px 2px 5px gray;
}


.timestamp{
  bottom: 20%;
  background-color: black;
  color: white;
  box-shadow: 2px 2px 5px gray;
}

.timestamp::before{
  content: ''; 
  position: absolute; 
  top: 6px; 
  left: -8px; 
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; 
  border-right: 8px solid black; 
}

.card .info_values{
  background-color: rgb(230, 230, 230);
}

.chart_bloc {
  position: sticky;
  top: 1.5em;
}

.titleChartline {
  font-weight: 600;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  width: 20%;
  margin: 0.5em;
  padding: 0.7em;
  border: none;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  background-color: rgb(200, 200, 200);
}

.return {
  background-color: rgb(254, 202, 202);
  width: min-content;
  display: flex;
  align-items: center;
}

.return i {
  padding-right: 5px;
}

.return:hover {
  background-color: rgb(255, 182, 182);
  box-shadow: 0 0 5px rgb(255, 39, 39);
}

.download:hover {
  background-color: rgb(177, 202, 255);
  box-shadow: 0 0 5px rgb(83, 138, 255);
}

.export:hover {
  background-color: rgb(182, 255, 192);
  box-shadow: 0 0 5px rgb(83, 255, 106);
}

.pop-up {
  display: contents;
}

select {
  padding: 0.2em;
  margin-top: 5px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  font-size: 0.9em;
  width: 100%;
  background-color: #f8f9fa;
  cursor: pointer;
}

select:hover {
  background-color: rgb(230, 230, 230);
}

.buttons_infos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.buttons_infos i {
  font-size: 1.1em;
  margin-left: 0.3em;
}

.buttons_infos button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0.5em;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  width: 170px;
  margin: 0.5em;
}

.buttons_infos button:hover {
  background-color: rgb(218, 217, 217);
}

.alert_premium {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.275);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert_premium p {
  font-size: 1.3em;
  border-radius: 10px;
  box-shadow: 0 0 4px black;
}

.alert_premium a {
  text-decoration: underline;
}

.blurred-block {
  filter: blur(2px);
}

/* #contentToCopy {
  visibility: hidden;
} */

.api_code {
  font-size: 1em;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

.api_code_message {
  position: fixed;
  background-color: #a9e6c0;
  border-radius: 5px;
  border: 2px solid rgb(26, 137, 44);
  font-size: 0.9em;
  padding: 0.2em 0.7em;
  left: 0;
  transform: translate(1em, -0.5em);
  pointer-events: none;
}

.api_code_copy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.api_code_copy i {
  font-size: 1.3em;
  padding: 0.2em;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.api_code_copy i:hover {
  background-color: rgba(0, 0, 0, 0.184);
}

.api_code_copy i:active {
  background-color: rgba(0, 0, 0, 0.533);
}

.amcharts-amexport-menu-level-0.amcharts-amexport-top {
    top: 110px !important ;
}

.menu-button {
  cursor: pointer;
  position: absolute;
  top: 7em;
  right: 1.6em;
}

.fa-bars, .fa-xmark{
  font-size: 1.8em;
}

.opacity_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.382);
  z-index: 99;
}

.infos_chart {
  position: fixed;
  top: 13vh;
  left: 15%;
  width: 70%;
  padding: 1em;
  border-radius: 20px;
  z-index: 100;
  background-color: white;
  box-shadow: 0 0 5px black;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.infos_chart h2 {
  font-weight: 600;
  margin: 1em;
}

.infos_chart button:hover {
  background-color: rgb(218, 217, 217);
}

.infos_chart button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0.4em;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  width: 190px;
  margin: 0.5em;
  font-size: 0.9em;
}

.infos_chart table td,
.infos_chart table th {
  border: 1px solid #333;
  padding: 0.5em;
  text-align: center;
}

.infos_chart table {
  width: 80%;
  margin: 0.5em;
}

.infos_chart h2,
.infos_chart h3 {
  text-align: center;
}

.fade-in {
  opacity: 1;
}

.menu-burger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.9em;
  height: 1.9em;
  border-radius: 50%;
  position: absolute;
  top: 0.4em;
  right: 0.6em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
</style>