<template>
  <section class="support container d-flex flex-wrap p-3 raw">
    <div class="col-12">
      <h1 class="h2">Support</h1>
      <hr>
    </div>
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-center align-self-center">
        <div class="card mt-5 mb-5 p-5 w-50">
            <div class="card-body d-flex justify-content-center text-center">
              <p class="">If you need help, please get in touch with our support : <a href="mailto:support@taceconomics.com" target="_blank">support@taceconomics.com</a></p>
            </div>
            <div class="card-body d-inline-flex align-items-center justify-content-center p-3">
              <a href="https://github.com/taceconomics" class="d-flex align-content-center" target="_blank">
                <img class="me-3" src="../assets/img/github.png" alt="" width="30">
                <p class="m-auto">GitHub Repository : TAC ECONOMICS</p>
              </a>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "VSupport",
}

</script>

<style scoped>
  .support{
    height: 80vh;
  }
</style>
