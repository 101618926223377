<template>
  <nav class="navbar navbar-expand-lg" style="z-index: 2;">
    <div class="container">
      <a class="navbar-brand" href="/" aria-label="link to go back to the home page"><img class="logo" src="../../../assets/img/Full-Wordmark_White-8.png" alt="logo of the company"></a>
      <form class="d-flex form_search" role="search" action="/search" method="get">
        <div v-if="isLoggedIn" class="input-group input-group-sm ms-3 search">
          <input v-model="searchQuery" type="search" class="form-control" name="q" placeholder="Search indicators, countries...">
          <!-- <button class="btn btn-secondary" type="button"><i class="fa fa-search"></i></button> -->
          <button type="submit"><i class="fa fa-search"></i></button>
        </div>
      </form>
      <div class="menu-overlay" :class="{ 'active': isMenuOpen }" @click="toggleMenu">
        <div class="menu-content" @click.stop id="navbarText">
          <div class="menu_burger_close" :class="{ 'active': isMenuOpen }" @click="toggleMenu">
            <i class="fa-solid fa-xmark" style="color: #ffffff;"></i>
          </div>
          <ul class="navbar-nav ms-auto center-links">
            <li class="nav-item">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li class="nav-item"> &nbsp;&nbsp;&nbsp;&nbsp;</li>
            <!--<li class="nav-item" @click="closeMenu">
              <router-link to="/chat" class="nav-link menu-separator link_underline"><i class="fas fa-comment me-1"></i>
                Alber</router-link>
            </li>-->
            <li v-if="isLoggedIn" class="nav-item" @click="closeMenu">
              <router-link to="/datasets" class="nav-link menu-separator link_underline">
                  <i class="fa-solid fa-database me-1"></i>
                Datasets</router-link>
            </li>
            <li v-if="isLoggedIn" class="nav-item" @click="closeMenu">
              <router-link to="/dashboards" class="nav-link menu-separator link_underline"><i
                  class="fa-solid fa-table-columns me-1"></i>
                Dashboards</router-link>
            </li>
            <li v-if="isLoggedIn" class="nav-item" @click="closeMenu">
              <div class="nav-link menu-separator link_underline">
                <DropdownCustom />
              </div>
            </li>
            <!--<li class="nav-item" @click="closeMenu">
              <router-link to="/appESG" class="nav-link menu-separator link_underline"><i
                  class="fa fa-globe-americas me-2"></i>App ESG</router-link>
            </li>-->
			<li class="nav-item" @click="closeMenu">
              <router-link to="/support" class="nav-link menu-separator link_underline"><i
                  class="fa fa-solid fa-circle-info me-2"></i>Support</router-link>
            </li>
			<li class="nav-item" @click="closeMenu">
              <router-link to="/Download" class="nav-link menu-separator link_underline"><i
                  class="fa fa-solid fa-download me-2"></i>Download</router-link>
            </li>
            <!--
          <router-link to="/models" class="nav-link pe-3 menu-separator"><i class="fa-solid fa-atom"></i> Models</router-link>
          -->
          <!--<li v-if="!isLoggedIn" class="nav-item">
              <router-link to="/login" class="nav-link link_underline me-3" @click="closeMenu">Login</router-link>
          <button class="btn btn-outline-light border-0 ps-1 me-2" @click.prevent="login">Log In</button>
            </li> -->
          <!--  <li v-if="!isLoggedIn" class="nav-item me-3">
              <button class="btn btn_upgrade" @click="closeMenu" @click.prevent="signup">Sign Up</button>
            </li> -->
            <!-- <li v-if="!isLoggedIn" class="nav-item">
            <button class="btn btn-plans" @click.prevent="plans">Plans &amp; Access</button>
          </li> -->
            <!--
          <li v-if="isLoggedIn" class="nav-item">
            <router-link v-if="isLoggedIn" to="/user" class="nav-link me-3"><i class="fa-regular fa-user"></i></router-link>
          </li>
          <li v-if="isLoggedIn" class="nav-item">
            <router-link v-if="isLoggedIn" to="/" @click="logout" class="nav-link me-3"><i class="fa-solid fa-right-from-bracket"></i></router-link>
          </li>

          -->
            <li v-if="isLoggedIn" class="nav-item dropdown me-4">
              <button class="btn btn-outline-light border-0 dropdown-toggle user_btn" style="color: white;" type="button" id="userDropDown"
                data-bs-toggle="dropdown" aria-label="button to send the form" aria-expanded="false">
                <i class="fa-regular fa-user" style="color: white;"></i>
              </button>
              <div class="list_menu dropdown-menu dropdown-menu-right dropdown-menu-end">
                <!-- <router-link to="/user" class="dropdown-item" @click="closeMenu"><i
                    class="fa-solid fa-user me-3"></i>Profile</router-link> -->
                <a href="/user" class="dropdown-item" @click="closeMenu"><i class="fa-solid fa-user me-3"></i>Profile</a>
                <!-- <router-link to="/download" class="dropdown-item" @click="closeMenu"><i
                    class="fa-sharp fa-solid fa-download me-3"></i>Download</router-link> -->
                <!--<a href="/download" class="dropdown-item" @click="closeMenu"><i class="fa-sharp fa-solid fa-download me-3"></i>Download</a>-->
                <!-- <a href="/support" class="dropdown-item" @click="closeMenu"><i class="fa-solid fa-circle-info me-3"></i>Support</a> -->
                <a href="#" class="dropdown-item" @click="logout"><i class="fa-solid fa-power-off me-3"></i>Log out</a>
              </div>
            </li>

            <!-- <li v-if="isLoggedIn" class="nav-item dropdown menu_user_responsive">
              <router-link to="/user" class="nav-link" @click="closeMenu"><i
                  class="fa-solid fa-user me-3"></i>Profile</router-link>
              <router-link to="/download" class="nav-link" @click="closeMenu"><i
                  class="fa-sharp fa-solid fa-download me-3"></i>Download</router-link>
              <a href="#" class="nav-link" @click="logout"><i class="fa-solid fa-power-off me-3"></i>Log
                out</a>
            </li> -->

            <!-- <li v-if="isLoggedIn" class="nav-item">
              <button class="btn btn_upgrade" @click="closeMenu" @click.prevent="plans">Upgrade</button>
            </li> -->

          </ul>
        </div>
      </div>

      <button class="menu_burger" type="button" @click="toggleMenu">
        <i class="fa-solid fa-bars"></i>
      </button>

    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import DropdownCustom from "../Dropdown/DropdownCustom.vue";

export default {
  name: 'TheNavbar',
  components: {
    DropdownCustom,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  data() {
    return {
      isMenuOpen: false,
      searchQuery: '',
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    async login() {
      this.$router.push('/login')
    },
    async signup() {
      this.$router.push('/signup')
    },
    async plans() {
      this.$router.push('/pricing')
    },
    async logout() {
      await this.$store.dispatch('email');
      await this.$store.dispatch('token');
      await this.$store.dispatch('refresh_token');
      this.$router.push('/')
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        const params = new URLSearchParams(this.$route.query);
        const datasetId = params.get('dataset');
        if (datasetId) {
          this.searchQuery = `${datasetId}:`;
        }
      },
    },
  },
  
}
</script>

<style scoped>
.search input {
  background-color: #001046bb;
  border: none;
  padding: 0.5em 1.5em;
  color: white;
  border-radius: 40px;
  font-size: 1em;
  transition: all 0.2s ease-in-out;
}

.search button {
  background-color: #001046bb;
  color: white;
  border-radius: 40px;
  font-size: 1em;
  border: none;
  width: 50px;
  transition: all 0.2s ease-in-out;
}

.search input:hover,
.search button:hover {
  background-color: #000e41;
  box-shadow: 0 0 5px rgb(104, 104, 104);
}

.form_search {
  width: 35%;
}

.search input::placeholder {
  transition: all 0.2s ease-in-out;
  font-family: 'Poppins';
}

.nav-link {
  font-size: 0.95rem;
  width: max-content;
  color: var(--te-color-light-1) !important;
  font-family: 'Poppins';
}

.nav-link:hover {
  color: var(--te-color-blue-2) !important;
}

.logo {
  width: 70px;
  text-decoration: none;
}

.navbar {
  background-color: #021A6F;
  padding: 1em 0;
}

.btn_upgrade {
  border-radius: 20px;
  padding: 0.4em 1.5em;
  background-color: white;
  border: solid 2px white;
  color: #021A6F;
  font-weight: 600;
  font-family: 'Poppins';
}

.btn_upgrade:hover {
  background-color: #021A6F;
  color: white;
  box-shadow: 0 0 8px white;
}

.btn_upgrade:active {
  background-color: white;
  color: #021A6F;
}

.navbar-brand {
  /* font-size: 1rem; */
  color: #999;
  border-radius: 10px;
}

.btn-plans {
  background: var(--te-color-blue-2) !important;
}

#navbarText ul {
  align-items: center;
}

.menu_burger,
.menu_burger_close {
  display: none;
  font-size: 2em;
  position: absolute;
  top: 0.6em;
  right: 1em;
  border: none;
  background: none;
  cursor: pointer;
}

.menu_user_responsive {
  display: flex;
  flex-direction: column;
}

.menu_burger_close {
  font-size: 2.4em;
  right: 1.05em;
  top: 0.4em;
}

.menu_burger i {
  color: white;
}

.link_underline:link {
  display: inline-block;
  color: white;
  font-size: 1.1em;
  padding-right: 1.5em;
}

.link_underline::after {
  content: '';
  height: 1px;
  margin-top: 1px;
  display: block;
  background: rgb(167, 193, 227);
  transition: .5s;
  transform: scaleX(0);
  transform-origin: center;
}

.link_underline:hover {
  color: rgb(167, 193, 227);
}

.link_underline:hover::after {
  transform: scaleX(1);
}

.link_underline:active {
  color: rgb(149, 177, 211)!important;
}

.link_underline:active::after {
  background: rgb(149, 177, 211)!important;
}

.user_btn {
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}

.user_btn i {
  font-size: 1.1em;
  padding: 0.3em 0.2em;
}

.list_menu {
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(164, 164, 164);
  padding: 0.5em;
}

/* .list_menu router-link, */
.list_menu a {
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  font-size: 1.1em;
}

.list_menu a:active {
  transition: all 0.1s ease-in-out;
}

@media screen and (max-width: 991px) {
  .form_search {
    width: 80%;
  }

  .user_btn {
    display: none;
  }

  .menu_user_responsive {
    display: block;
  }

  .navbar {
    height: 5.5em;
  }

  .navbar>div {
    margin-left: 1em;
  }

  .menu_burger,
  .menu_burger_close {
    display: block;
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.817);
    opacity: 0;
    margin-right: -600px;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, margin-right 0.3s ease;
    z-index: 9997;
  }

  .menu-overlay.active {
    opacity: 1;
    visibility: visible;
    margin-right: 0;
  }

  .center-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .nav-item {
    font-size: 1.6em;
  }

  .nav-item i {
    display: none;
  }

  .nav-item button {
    font-size: 0.7em;
  }

  .link_underline,
  .nav-item {
    padding: 0.4em !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 840px) {
  .container {
    justify-content: center;
    margin-right: 0;
  }
  .form_search {
    width: 70%;
  }
}

@media screen and (max-width: 580px) {
  .form_search {
    width: 60%;
    margin-right: 15vw;
  }

  .navbar>div {
    margin-left: 0em;
  }
}

@media screen and (max-width: 450px) {
  .form_search {
    width: 50%;
    margin-right: 15vw;
  }
}

@media screen and (max-width: 768px) {
  input::placeholder {
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    justify-content: start;
    margin-right: 0;
  }
  input::placeholder {
    font-size: 10px;
  }
}

@media screen and (min-width: 1200px) {
  input::placeholder {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-item {
    font-size: 12px;
  }
}

</style>
