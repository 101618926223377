<template>
    <div v-if="infos_chart" class="opacity_background"></div>
    <div v-if="infos_chart" class="infos_chart fade-in bg-white d-flex justify-content-center align-items-center flex-wrap">
        <div class="title_infos col-12">
            <h2 class="fw-bold text-center">Country Environmental Risk</h2>
        </div>

        <div class="buttons_infos col-12 d-inline-flex justify-content-center align-items-center">

            <button @click="downloadPDF()">PDF (graph) <i class="fa-solid fa-file-pdf"></i></button>
            <button @click="downloadPNG()">PNG (graph) <i class="fa-solid fa-file-image"></i></button>
            <button @click="exportXLSX()">Excel (data) <i class="fa-solid fa-file-excel"></i></button>
            <button @click="exportCSV()">CSV (data) <i class="fa-solid fa-file-csv"></i></button>

        </div>

        <div class="menu-burger" @click="close_infos_chart">
            <i class="fa-solid fa-xmark"></i>
        </div>
    </div>

    <!-- TODO Fix afficher le bloc de la carte en Sticky pour avoir un aperçu lors du changement des poids -->
    <article id="map-indicator-medium" class="border border-1 border-dark-subtle rounded p-3 png_pdf">
        <div class="map-indicator">
            <div class="header">
                <div class="d-flex align-items-center">
                    <a href="https://app.taceconomics.com/" class="col-1 ps-2">
                        <img src="../../assets/img/taclogo.png" alt="" width="60">
                    </a>
                    <h2 class="col-10 fw-bold text-center">Country environmental Risk</h2>
                    <div @click="display_infos_chart" class="menu-button close close2 col-1 ms-auto me-2 d-flex">
                        <i class="fa-solid fa-bars ms-auto pe-2"></i>
                    </div>
                </div>
            </div>
            <div id="charts-map" class="text-center p-2"></div>
            <div class="w-100 d-flex my-0 px-2">
                <small class="col-6 text-end" ><b>Risk : </b>{{ risk }}</small>
                <small class="col-6 text-end" ><b>Source : </b>Tac Economics</small>
            </div>
        </div>
    </article>
</template>


<script setup>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import * as d3 from 'd3-dsv'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import {  ref, onMounted} from 'vue';
import { defineProps, watch } from 'vue';


const props = defineProps({
  data: Object,
  risk: String,
});

let map;
let mapData = ref([]);
const infos_chart = ref(false);


watch(() => props.data, async () => {
    await getData();
    createMap('charts-map', mapData.value);
}, { deep: true });

async function getData(){
    mapData.value = props.data[props.risk].map(item => {
        return {
            id: convertIso(item.country_id, item.id),
            iso3: item.country_id,
            value: item.value,
            value_str: item.value !== null ? Math.round(item.value * 100) / 100 : '',
            fill: am4core.color(getColor(item.value)),
        }
    });
}

function getColor(value){
    const ranges = [1, 2.5, 3, 3.5, 4, 5.01];
	const colors = ['#cf0f26', '#ff6969', '#f5b20b', '#00c898', '#008263'];
    switch (value) {
        case 0:
            return '#808080';
        case null:
            return '#808080';
        case 'N/A':
            return '#808080';
        case 'NaN':
            return '#808080';
        case value >= 100:
            return colors[4];
        default:
            for (let i = 1; i < ranges.length; i++) {
                if (value >= ranges[i - 1] && value < ranges[i]) {
                    return colors[i - 1];
                }
        }
        break;
    }
}


function createMap(containerId, data) {
    // Dispose of the old map if it exists
    if (map) {
        map.dispose();
    }

    // Create a new map
    map = am4core.create(containerId, am4maps.MapChart);
    map.geodata = am4geodata_worldLow;

    let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;

    map.projection = new am4maps.projections.Miller();
    map.homeGeoPoint = {
        latitude: 30, 
        longitude: 50
    };
    polygonSeries.data = data;
    polygonSeries.exclude = ["AQ"];

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.propertyFields.fill = "fill";
    polygonTemplate.applyOnClones = true;
    polygonTemplate.togglable = false;
    polygonTemplate.tooltipText = "{name} : {value_str}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeOpacity = 0.5;
    polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    map.legend = new am4maps.Legend();
    var legend = map.legend;
    legend.background.fill = am4core.color("#000");
    legend.background.fillOpacity = 0.05;
    legend.width = 275;
    legend.align = "right";
    legend.padding(10, 15, map.chartContainer.maxHeight, 15);
    legend.data = [{
        "name": "Low (1 to 2.5)",
        "fill":"#cf0f26"
    }, {
        "name": "Moderately Low (2.51 to 3)",
        "fill": "#ff6969"
    }, {
        "name": "Moderately High (3.01 to 3.50)",
        "fill": "#f5b20b"
    }, {
        "name": "High (3.51 to 4)",
        "fill": "#008263"
    }, {
        "name": "Very High (4.01 to 5)",
        "fill": "#00c898"
    },
    {
        "name": "no/partial assessment \ndue to lack of data",
        "fill": "#808080"
    }];
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;


    var legendTitle = legend.createChild(am4core.Label);
    legendTitle.text = "Risk level :";
}

function downloadPNG() {
    // Télécharge l'image du graphique en PNG
    html2canvas(document.querySelector('.png_pdf')).then((canvas) => {

    const newCanvas = document.createElement('canvas');
    const newCtx = newCanvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    newCanvas.width = width;
    newCanvas.height = height;

    newCtx.drawImage(canvas, 0, 0, width, height, 0, 0, width, height);

    const link = document.createElement('a');
    link.href = newCanvas.toDataURL('image/png');
    link.download = 'TACECONOMICS_E_'+props.risk+'.png';
    link.click();
    });
}

function downloadPDF() {
    // Télécharge l'image du graphique en PDF
    html2canvas(document.querySelector('.png_pdf')).then((canvas) => {

    const cropX = 0;
    const cropY = 0;
    const cropWidth = canvas.width;
    const cropHeight = canvas.height;

    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;
    const croppedCtx = croppedCanvas.getContext('2d');

    croppedCtx.drawImage(canvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    const imgData = croppedCanvas.toDataURL('image/png');

    var pdf;
    pdf = new jsPDF({ orientation: 'landscape' })

    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = pdf.internal.pageSize.getHeight();

    var imgWidth = pdfWidth;
    var imgHeight = (cropHeight/cropWidth) * imgWidth;

    if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = (cropWidth/cropHeight) * imgHeight;
    }

    var offsetX = (pdfWidth - imgWidth) / 2;
    var offsetY = (pdfHeight - imgHeight) / 2;

    pdf.addImage(imgData, 'PNG', offsetX, offsetY, imgWidth, imgHeight);

    pdf.save('TACECONOMICS_E_'+props.risk+'.pdf');
    });
}

function exportCSV() {
    // Télécharge les données du graphique en CSV
    const data = mapData.value;
    
    // Remplacer l'en-tête "value" par "Autre libellé"
    const csvData = data.map(item => ({
        Countries: item.iso3,
        value: (item.value && item.value !== 0) ? item.value : '',
    }));

    const csv = d3.csvFormat(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'TACECONOMICS_E_'+props.risk+'.csv');
}

function exportXLSX() {
    // Télécharge les données du graphique en XLSX
    const data = mapData.value;
    
    // Remplacer l'en-tête "value" par "Autre libellé"
    const modifiedData = data.map(item => ({
        Countries: item.iso3,
        value: (item.value && item.value !== 0) ? item.value : '',
    }));

      const workbook = XLSX.utils.book_new();
      const sheet = XLSX.utils.json_to_sheet(modifiedData, { header: ["Countries"] });
      XLSX.utils.book_append_sheet(workbook, sheet, 'Chart Data');
      const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(blob, 'TACECONOMICS_E_'+props.risk+'.xlsx');
}

function display_infos_chart() {
    infos_chart.value = true;
}
    
function close_infos_chart() {
    infos_chart.value = false;
}

function convertIso(iso3, iso2) {
      // Convertit un iso3 en iso2 et vice-versa
      const isoCodes = {
        AFG: "AF",
        ALA: "AX",
        ALB: "AL",
        DZA: "DZ",
        ASM: "AS",
        AND: "AD",
        AGO: "AO",
        AIA: "AI",
        ATA: "AQ",
        ATG: "AG",
        ARG: "AR",
        ARM: "AM",
        ABW: "AW",
        AUS: "AU",
        AUT: "AT",
        AZE: "AZ",
        BHS: "BS",
        BHR: "BH",
        BGD: "BD",
        BRB: "BB",
        BLR: "BY",
        BEL: "BE",
        BLZ: "BZ",
        BEN: "BJ",
        BMU: "BM",
        BTN: "BT",
        BOL: "BO",
        BES: "BQ",
        BIH: "BA",
        BWA: "BW",
        BVT: "BV",
        BRA: "BR",
        IOT: "IO",
        BRN: "BN",
        BGR: "BG",
        BFA: "BF",
        BDI: "BI",
        CPV: "CV",
        KHM: "KH",
        CMR: "CM",
        CAN: "CA",
        CYM: "KY",
        CAF: "CF",
        TCD: "TD",
        CHL: "CL",
        CHN: "CN",
        CXR: "CX",
        CCK: "CC",
        COL: "CO",
        COM: "KM",
        COG: "CG",
        COD: "CD",
        COK: "CK",
        CRI: "CR",
        CIV: "CI",
        HRV: "HR",
        CUB: "CU",
        CUW: "CW",
        CYP: "CY",
        CZE: "CZ",
        DNK: "DK",
        DJI: "DJ",
        DMA: "DM",
        DOM: "DO",
        ECU: "EC",
        EGY: "EG",
        SLV: "SV",
        GNQ: "GQ",
        ERI: "ER",
        EST: "EE",
        SWZ: "SZ",
        ETH: "ET",
        FLK: "FK",
        FRO: "FO",
        FJI: "FJ",
        FIN: "FI",
        FRA: "FR",
        GUF: "GF",
        PYF: "PF",
        ATF: "TF",
        GAB: "GA",
        GMB: "GM",
        GEO: "GE",
        DEU: "DE",
        GHA: "GH",
        GIB: "GI",
        GRC: "GR",
        GRL: "GL",
        GRD: "GD",
        GLP: "GP",
        GUM: "GU",
        GTM: "GT",
        GGY: "GG",
        GIN: "GN",
        GNB: "GW",
        GUY: "GY",
        HTI: "HT",
        HMD: "HM",
        VAT: "VA",
        HND: "HN",
        HKG: "HK",
        HUN: "HU",
        ISL: "IS",
        IND: "IN",
        IDN: "ID",
        IRN: "IR",
        IRQ: "IQ",
        IRL: "IE",
        IMN: "IM",
        ISR: "IL",
        ITA: "IT",
        JAM: "JM",
        JPN: "JP",
        JEY: "JE",
        JOR: "JO",
        KAZ: "KZ",
        KEN: "KE",
        KIR: "KI",
        PRK: "KP",
        KOR: "KR",
        KWT: "KW",
        KGZ: "KG",
        LAO: "LA",
        LVA: "LV",
        LBN: "LB",
        LSO: "LS",
        LBR: "LR",
        LBY: "LY",
        LIE: "LI",
        LTU: "LT",
        LUX: "LU",
        MAC: "MO",
        MKD: "MK",
        MDG: "MG",
        MWI: "MW",
        MYS: "MY",
        MDV: "MV",
        MLI: "ML",
        MLT: "MT",
        MHL: "MH",
        MTQ: "MQ",
        MRT: "MR",
        MUS: "MU",
        MYT: "YT",
        MEX: "MX",
        FSM: "FM",
        MDA: "MD",
        MCO: "MC",
        MNG: "MN",
        MNE: "ME",
        MSR: "MS",
        MAR: "MA",
        MOZ: "MZ",
        MMR: "MM",
        NAM: "NA",
        NRU: "NR",
        NPL: "NP",
        NLD: "NL",
        NCL: "NC",
        NZL: "NZ",
        NIC: "NI",
        NER: "NE",
        NGA: "NG",
        NIU: "NU",
        NFK: "NF",
        MNP: "MP",
        NOR: "NO",
        OMN: "OM",
        PAK: "PK",
        PLW: "PW",
        PSE: "PS",
        PAN: "PA",
        PNG: "PG",
        PRY: "PY",
        PER: "PE",
        PHL: "PH",
        PCN: "PN",
        POL: "PL",
        PRT: "PT",
        PRI: "PR",
        QAT: "QA",
        REU: "RE",
        ROU: "RO",
        RUS: "RU",
        RWA: "RW",
        BLM: "BL",
        SHN: "SH",
        KNA: "KN",
        LCA: "LC",
        MAF: "MF",
        SPM: "PM",
        VCT: "VC",
        WSM: "WS",
        SMR: "SM",
        STP: "ST",
        SAU: "SA",
        SEN: "SN",
        SRB: "RS",
        SYC: "SC",
        SLE: "SL",
        SGP: "SG",
        SXM: "SX",
        SVK: "SK",
        SVN: "SI",
        SLB: "SB",
        SOM: "SO",
        ZAF: "ZA",
        SGS: "GS",
        SSD: "SS",
        ESP: "ES",
        LKA: "LK",
        SDN: "SD",
        SUR: "SR",
        SJM: "SJ",
        SWE: "SE",
        CHE: "CH",
        SYR: "SY",
        TWN: "TW",
        TJK: "TJ",
        TZA: "TZ",
        THA: "TH",
        TLS: "TL",
        TGO: "TG",
        TKL: "TK",
        TON: "TO",
        TTO: "TT",
        TUN: "TN",
        TUR: "TR",
        TKM: "TM",
        TCA: "TC",
        TUV: "TV",
        UGA: "UG",
        UKR: "UA",
        ARE: "AE",
        GBR: "GB",
        USA: "US",
        UMI: "UM",
        URY: "UY",
        UZB: "UZ",
        VUT: "VU",
        VEN: "VE",
        VNM: "VN",
        VGB: "VG",
        VIR: "VI",
        WLF: "WF",
        ESH: "EH",
        YEM: "YE",
        ZMB: "ZM",
        ZWE: "ZW"
      };

      let res = null

      if (iso3 != null) {
        res = isoCodes[iso3] || "";
      }
      else {
        for (let iso3 in isoCodes) {
          if (Object.prototype.hasOwnProperty.call(isoCodes, iso3) && isoCodes[iso3] === iso2) {
            res = iso3;
          }
        }
      }

      return res;
    }

    onMounted(async () => {
        await getData();
        createMap('charts-map', mapData.value);
    });

</script>


<style scoped>

#charts-map{
    width: 100%;
    height: 330px;
}

.map-indicator .header h2, .map-indicator .header h3{
    color: #1D124E;
    font-family: 'Poppins';
}


.map-indicator .header h2, .map-indicator .header h3{
    color: #1D124E;
    font-family: 'Poppins';
}

.menu-button {
  cursor: pointer;
}

.menu-button i{
    font-size: 1.8em;
}

.opacity_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.382);
  z-index: 99;
}

.infos_chart {
  position: fixed;
  top: 13vh;
  left: 15%;
  width: 70%;
  padding: 1em;
  border-radius: 20px;
  z-index: 100;
  box-shadow: 0 0 5px black;
  opacity: 0;
  transition: opacity 0.3s;
  overflow-y: auto;
}


.infos_chart button:hover {
  background-color: rgb(218, 217, 217);
}

.infos_chart button {
  border: none;
  padding: 0.4em;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  width: 190px;
  margin: 0.5em;
  font-size: 0.9em;
}

.fade-in {
  opacity: 1;
}

.menu-burger {
  border-radius: 50%;
  position: absolute;
  top: 0.4em;
  right: 1em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.menu-burger i{
    font-size: 1.8em;
}


</style>