<template>
    <article class="list_tab my-3 d-flex flex-wrap gap-3 justify-content-between">
        <div v-if="props.data" class="div-tab border rounded-2 overflow-auto w-100">
            <table class="tabIndicator table table-light table-striped m-0 text-dark">
                <thead>
                    <tr>
                        <th scope="col" @click="sortTable(null)">Country <i class="fa-solid fa-sort"></i></th>
                        <th v-for="(risk, index) in props.risks" :key="index" scope="col" @click="sortTable(risk)">{{ risk.charAt(0).toUpperCase() + risk.slice(1) }} <i class="fa-solid fa-sort"></i></th>
                    </tr>
                </thead>
                <tbody v-if="isDataLoaded == true">
                    <tr v-for="country_name in sortedCountries" :key="country_name">
                        <td>{{ country_name }}</td>
                        <td v-for="risk in props.risks" :key="risk">
                            {{ Math.round(currentData[risk].find(country => country.name === country_name).myvalue*100)/100 }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button @click="exportCSV()" class="btn btn-secondary ms-auto" >
            <i class="fa-solid fa-file-csv me-2"></i> Export to csv
        </button>
    </article>
</template>

<script setup>
import { defineProps, onMounted, ref, computed } from 'vue';
import * as d3 from 'd3-dsv';
import { saveAs } from 'file-saver';

const props = defineProps({
    data: Array,
    risks: Array,
    indicator: String,
});

const countriesNames = ref([]);
const currentData = ref([]);
const isDataLoaded = ref(false);
const sortKey = ref(null);
const sortAsc = ref(true);

function getCountryName(){
    props.risks.forEach(risk => {
        props.data[risk].forEach(country => {
            if (!countriesNames.value.includes(country.name)) {
                countriesNames.value.push(country.name);
            }
        });
    });
    return countriesNames.value;
}
function getIndicatorData(indicator){
    props.risks.forEach(risk => {
        currentData.value[risk] = props.data[risk].filter(country => country.symbol === indicator);
    });
    return currentData.value;
}

function sortTable(risk) {
    sortKey.value = risk;
    sortAsc.value = !sortAsc.value;
}

const sortedCountries = computed(() => {
    if (!sortKey.value) {
        return [...countriesNames.value].sort((a, b) => sortAsc.value ? a.localeCompare(b) : b.localeCompare(a));
    }
    return [...countriesNames.value].sort((a, b) => {
        const aValue = currentData.value[sortKey.value].find(country => country.name === a).myvalue;
        const bValue = currentData.value[sortKey.value].find(country => country.name === b).myvalue;
        return sortAsc.value ? aValue - bValue : bValue - aValue;
    });
});

function exportCSV() {
    let allData = [];

    countriesNames.value.forEach(country_name => {
        // Créer un objet pour stocker les données du pays
        let countryData = { Country: country_name };

        // Parcourir chaque risque
        props.risks.forEach(risk => {
            // Trouver les données du pays pour le risque actuel
            let countryRiskData = currentData.value[risk].find(country => country.name === country_name);

            // Ajouter les données du pays pour le risque actuel à countryData si la valeur n'est pas égale à 0
            if (countryRiskData && countryRiskData.myvalue !== 0) {
                countryData[risk] = Math.round(countryRiskData.myvalue * 100) / 100;
            }
        });

        // Ajouter countryData à allData
        allData.push(countryData);
    });

    const csv = d3.csvFormat(allData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'TACECONOMICS_CountryValues.csv');
}


onMounted(() => {
    getCountryName();
    getIndicatorData(props.indicator.symbol);
    isDataLoaded.value = true;
})

</script>

<style scoped>
.list_tab .div-tab {
    height: 45vh;
}

thead th{
    cursor: pointer;
}
</style>