<template>
    <div class="mt-3 position-relative d-flex align-items-center bg-light pt-2 pb-2 mb-4" style="border-radius: 5px; cursor: pointer;">
      <div
        class="sliding-bg p-1"
        :style="{ width: bgWidth, transform: `translateX(${bgPosition}px)` }"
      ></div>
  
      <p
        v-for="(item, index) in items"
        :key="index"
        @click="handleItemClick(index, $event, item.text)"
        class="ps-3 pe-3 mb-0 item d-flex align-items-center justify-content-center"
        :style="{ color: selectedItem === index ? 'black' : 'gray', width: '100%', zIndex: 1}"
        style="font-size: small;"
      >
        <i :class="item.icon" class="me-2"></i>
        {{ item.text }}
      </p>
    </div>
  </template>
  
  <script setup>
  import { ref, watch, defineProps, onMounted } from 'vue';
  
  const props = defineProps({
    items: {
      type: Array,
      required: true
    },
    defaultSelectedIndex: {
      type: Number,
      default: 0
    },
    onSelect: {
      type: Function,
      required: true
    }
  });
  
  const selectedItem = ref(props.defaultSelectedIndex);
  const bgPosition = ref(0);
  const bgWidth = ref('0px');
  
  watch(() => props.defaultSelectedIndex, (newIndex) => {
    selectedItem.value = newIndex;
  });
  
  const handleItemClick = (index, event, text) => {
    if (selectedItem.value !== index) {
      const targetElement = event.target;
      bgWidth.value = `${targetElement.offsetWidth}px`;
      bgPosition.value = targetElement.offsetLeft;
      selectedItem.value = index;
    }
  
    props.onSelect(text);
  };

  onMounted(() => {
    bgWidth.value = `${100 / props.items.length}%`
    bgPosition.value = 0;
  })

  </script>
  
  <style scoped>
  .sliding-bg {
    position: absolute;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid lightgray;
    transition: transform 0.5s ease, width 0.3s ease;
    z-index: 0;
  }
  </style>
  