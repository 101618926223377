<template>
  <div class="d-flex align-items-center justify-content-center mt-5">
    <div class="mx-auto border rounded bg-white shadow mt-5 mb-5 p-5 login-form">
      <img class="logo mx-auto d-block" src="../assets/img/taceconomics.png">
      <form @submit.prevent="resend">
        <h2 class="pt-2 text-center mb-4">Mail Reset Password</h2>
        <div class="text-center text">Check your email for a link to reset your password. If it does not appear within a
          few minutes, check your spam folder.</div>
        <button type="submit" class="mt-4 mb-4 btn btn-dark w-100" id="login_button">Resend</button>
      </form>
      <div class="text-center">If you have confirm your account, you can close this page</div>
      <div v-if="error" class="alert alert-warning mt-4 text-center">{{ error }}</div>
    </div>
  </div>
</template>


<script>
import api from '@/services/api'
import Cookies from "js-cookie";

export default {
  data() {
    return {
      error: '',
    }
  },
  methods: {
    resend() {
      // Renvoi du mail de réinitalisation
      api.post('/user/sendresetpwdmail', {
        'email': Cookies.get('mail')
      })
        .then(response => {

          this.response = response.data;

          this.token = this.response.usertoken;

          this.$store.dispatch('email', this.email);
          this.$store.dispatch('token', this.response.usertoken);
          this.$store.dispatch('refresh_token', this.response.refresh_token);

          setTimeout(() => this.$router.push({ path: '/forgotpwdmail' }), 5000);
        })
        .catch((error) => {
          this.error = error.response.data.error;
        });
    },
  }
};
</script>


<style scoped>
.logo {
  width: 5rem;
  height: auto;
  margin-top: -85px;
  margin-bottom: 20px;
}

.login-form,
.text {
  max-width: 400px;
}

.pointer {
  cursor: pointer;
}
</style>
