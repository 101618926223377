<template>
  <div class="download_bloc d-flex align-items-center justify-content-center align-items-lg-center pt-5">

    <div class="donwload_bloc_2">

      <!-- Présentation de Excel Add-in -->
      <img class="mb-4" src="../assets/img/taclogo.png" alt="Logo TAC" height="120" width="200">
      <h1 class="pb-2">Download Excel Add-in</h1>
      <p class="mb-4">Discover our Excel add-in to easily retrieve data from your Datalab application in just a few
        clicks. Import data directly into Excel in real-time without any programming required. Save valuable time by
        automating the data collection process while benefiting from automatic updates to always have the latest
        information at hand.</p>
      <p>Download the executable by clicking the link below. Windows operating system required (32 or 64 bits).</p>

      <!-- Lien de téléchargement -->
      <a class="download_link" href="/download/taceconomics_exceladdin.exe"
        download="taceconomics_exceladdin.exe">Download <i class="fa-brands fa-windows m-2"></i></a>

      <i class="mt-2">For users who have downloaded the previous version, uncheck Taceconomics Add-ins (File > Options > Add-ins >
        Reach...) then close and reopen Excel.</i>

    </div>

    <!-- Image d'illustration de Excel Add-in -->
    <div class="image-container">
      <img src="../assets/img/download_img.png" alt="Logo TAC" height="350" width="500">
    </div>
  </div>
</template>

<style scoped>

.download_bloc {
  padding: 0 10%;
}
.download_link {
  display: block;
  width: max-content;
  color: white;
  padding: 0.7em 2.3em;
  background-color: rgb(62, 121, 239);
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  font-size: 1.3em;
  text-align: center;
}

.download_link:hover {
  background-color: rgb(46, 95, 194);
}

.donwload_bloc_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.donwload_bloc_2 p {
  text-align: justify;
  font-size: 1.1em;
}

.donwload_bloc_2 > i {
  text-align: justify;
  font-size: 0.8em;
}

.image-container img {
  border-radius: 20px;
  box-shadow: 0 0 80px rgb(219, 219, 219);
  transition: all 0.2s ease-in-out;
  margin-left: 4em;
}

.image-container img:hover {
  transform: scale(1.01);
}

@media screen and (max-width:1215px) {
  .download_bloc {
    flex-direction: column;
  }

  .donwload_bloc_2 {
    align-items: center;
  }

  .download_link {
    width: 100%;
  }

  .image-container img {
    margin: 0;
    margin-top: 3em;
  }
}
</style>
