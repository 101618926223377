<template>
  <div class="content" style="font-size: 1.1rem; padding-right: 15px">
      <div class="text-center">
        <div class="">
          <div ref="dropdownRef" class="dropdown custom-dropdown">
            <!--<div v-if="isTabOpen" class="list_menu dropdown-menu dropdown-menu-right dropdown-menu-end">
              <router-link to="/products/GeopoliticalRisk" class="dropdown-item d-flex">
                <i class="fa-solid fa-book-atlas fa-xl me-3" style="margin-top: 10px;"></i>
                <strong>Geopolitical Risk</strong>
              </router-link>
              <router-link to="/products/RiskMonitor" href="#" class="dropdown-item d-flex">
                <i class="fa-brands fa-watchman-monitoring fa-xl me-3" style="margin-top: 10px;"></i>
                <strong>RiskMonitor</strong>
              </router-link>
              <router-link to="/products/methodology" href="#" class="dropdown-item d-flex">
                <i class="fa-solid fa-file fa-xl me-3" style="margin-top: 10px;"></i>
                <strong>Methodology</strong>
              </router-link>
            </div> -->

            <button class="btn border-0 dropdown-toggle user_btn" style="color: white;" type="button" id="userDropDown"
                data-bs-toggle="dropdown" aria-label="button to send the form" aria-expanded="false">
                <i class="fa-solid fa-truck-fast me-2 mt-1"></i>
                Products
              </button>
              <div class="list_menu dropdown-menu dropdown-menu-right dropdown-menu-center" style="margin-top: 10px;">
                <a href="/products/GeopoliticalRisk" class="dropdown-item" @click="closeMenu"><i class="fa-solid fa-book-atlas fa-xl me-3" style="margin-top: 10px;"></i>Geopolitical Risk</a>
                <a href="/products/RiskMonitor" class="dropdown-item" @click="logout"><i class="fa-brands fa-watchman-monitoring fa-xl me-3" style="margin-top: 10px;"></i>Risk Monitor</a>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const isTabOpen = ref(false);
const dropdownRef = ref(null);

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isTabOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

</script>

<style scoped>
a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

a, a:hover {
  text-decoration: none !important;
}

.content {
  padding: 0rem 0;
}

.list_menu {
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(164, 164, 164);
  padding: 0.5em;
}

h2 {
  font-size: 20px;
}

.custom-dropdown {
  position: relative;
}

.custom-dropdown .btn:active, .custom-dropdown .btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #000;
  outline: none;
}

.custom-dropdown .btn.btn-custom {
  border: 1px solid #efefef;
}

.custom-dropdown .dropdown-link {
  color: #ffffff;
  display: inline-block;
  position: relative;
}

.custom-dropdown .dropdown-item {
  font-size: 14px;
  color: #727272c2;
  border-bottom: 1px solid #efefef;
  padding-top: 10px;
  padding-left: 35px;
  padding-bottom: 10px;
  position: relative;
}

.custom-dropdown .dropdown-item span {
  font-size: 13px;
  color: #999;
}

.custom-dropdown .dropdown-item:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #efefef;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.custom-dropdown .dropdown-item:hover {
  background-color: white;
  color: #000;
}

.custom-dropdown .dropdown-item:focus {
  background-color: white;
}

.custom-dropdown .dropdown-item strong:hover {
  color: #000;
}

.custom-dropdown .dropdown-item:hover:before {
  top: 50%;
  background: #007bff;
}

.custom-dropdown .dropdown-menu-custom {
  border: 1px solid rgba(196, 196, 196, 0.774);
  background-color: white;
  margin-top: 20px !important;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 0;
  z-index: 99999999;
  padding-bottom: 0;
  opacity: 1;
  -webkit-transition: .3s margin-top ease, .3s opacity ease, .3s visibility ease;
  -o-transition: .3s margin-top ease, .3s opacity ease, .3s visibility ease;
  transition: .3s margin-top ease, .3s opacity ease, .3s visibility ease;
  visibility: visible;
}

</style>