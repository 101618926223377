<template>
      <h2 class="pt-3"><i class="fa-solid fa-book-atlas me-3"></i>GPSI - GeoPolitical Sensitivity Index</h2>
      <hr />
      <SlidingMenu :items="items" @select="selectItem" :activeTab="activeTab" />
      <!--<div class="d-flex" style="height: 100%;">
        <div class="border bg-white" style="width: 100%">
          <div id="chartdiv" style="width: 100%; height: 500px;"></div>
        </div>
      </div> -->
  
      <div class="d-flex" style="height: 100%;">
        <div class="border bg-white" :style="{ width: isOpen ? '50%' : '100%' }">
          <!---<div id="chartdiv" style="width: 100%; height: 500px;"></div>-->
          <GeopoliticalMap
            :data="countries"
            :ItemSelected="ItemSelected"
            :items="items"
            :fetchData="fetchedData"
            @updateIndicator="HandleUpdateIndicator"
          />
        </div>
        <div class="ms-2 border expandable" :class="{ open: isOpen }" style="width: 50%;">
          <div v-if="isOpen">
          <div class="d-flex" style="justify-content: end;">
              <div class="d-flex" style="justify-content: end;">
                <p class="border p-2 m-1" style="border-radius: 5px; cursor: pointer;" @click="toggleOpen">Close</p>
              </div>
          </div>
          <div style="max-height: 500px;">
            <RadarGeoRisk :selectedgeopol="selectedCountry" />
          </div>
          </div>
        </div>
      </div>
  
          <h2 class="pt-5">{{ ItemSelected }} Country Ranking </h2>
      <hr />
  
      <div class="table-responsive" style="max-height: 500px; overflow-y: auto;">
        <table class="table custom-table mt-4" style="table-layout: fixed; width: 100%;">
            <thead class="sticky-header">
                <tr>
                    <th v-for="header in headers" :key="header.text" @click="header.sortable ? sortByCriteria(header.text): null" 
                    :style="{
                        cursor: header.sortable ? 'pointer' : null, 
                        width: header.text === 'Rank' ? '10%' : '16.66%'
                    }"  
                        :class="header.text === ItemSelected ? 'selected-header' : 'dimmed-header'"
                        class="text-center">
                        <div style="display: block; text-align: center;">
                            <i :class="header.icon" class="me-2 ms-2 text-center"></i>
                            <div class="mt-3">
                                {{ header.text }}
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody v-if="Top20Countries.length > 0" style="height: 400px; overflow-y: auto; display: table-row-group;">
                <tr class="items" v-for="(item, index) in Top20Countries" :key="index">
                    <td class="text-center" style="width: 10.5%;">{{ item.rank }}</td>
                    <td class="text-center">{{ item.country }}</td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'GeoPolitical Sensitivity Index' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.gpsi }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.gpsi + '%', backgroundColor: getProgressColor(item.gpsi) }"
                                    :aria-valuenow="item.gpsi"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'Alliances' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.alliances }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.alliances + '%', backgroundColor: getProgressColor(item.alliances) }"
                                    :aria-valuenow="item.alliances"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'Current Conflicts & Sanctions' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.currentConflicts }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.currentConflicts + '%', backgroundColor: getProgressColor(item.currentConflicts) }"
                                    :aria-valuenow="item.currentConflicts"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="d-flex align-items-center">
                            <span :class="ItemSelected === 'Critical Locks & Knots' ? 'highlighted-value' : 'dimmed-value'" class="me-2">{{ item.criticalLocks }}</span>
                            <div class="progress" style="width: 100%;">
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                    :style="{ width: item.criticalLocks + '%', backgroundColor: getProgressColor(item.criticalLocks) }"
                                    :aria-valuenow="item.criticalLocks"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
      <div class="d-flex justify-content-between">
        <p style="font-size: small;">{{ ItemSelected }} from 0 (very low sensitivity) to 100 (very high sensitivity)</p>
        <p style="font-size: small;">Source: TAC ECONOMICS</p>
      </div>
  </template>
  
  <script setup>
  /* eslint-disable */
  import { onMounted, ref, defineProps, watch } from "vue";
  import { countryNames, mapIso2ToIso3 } from "../../utils/CountryMapping";
  import RadarGeoRisk from "./GeopoliticalRadarRisk.vue";
  import SlidingMenu from "../UI/Menu/SlidingMenu.vue";
  import GeopoliticalMap from "./GeopoliticalMap.vue";
  import api from '@/services/api'

  const props = defineProps({
    data: {
      type: Array,
      required: true,
    }
  });

  const items = ref([
    { text: "GeoPolitical Sensitivity Index", icon: "fa-solid fa-book-atlas fa-lg" },
    { text: "Alliances", icon: "fa-solid fa-handshake fa-lg" },
    { text: "Current Conflicts & Sanctions", icon: "fa-solid fa-triangle-exclamation fa-lg" },
    { text: "Critical Locks & Knots", icon: "fa-brands fa-joomla fa-lg" }
  ]);
  
  const ItemSelected = ref("GeoPolitical Sensitivity Index");
  
  function getProgressColor(value) {
    if (value > 60) {
        return '#a82418'; // Dark red
    } else if (value > 55) {
        return '#c23728'; // Medium dark red
    } else if (value > 50) {
        return '#e48b87'; // Intermediate red
    } else if (value > 45) {
        return '#f2cacb'; // Light red
    } else if (value > 40) {
        return '#faf1d2'; // Yellow
    } else if (value > 35) {
        return '#b2daf5'; // Light blue
    } else if (value > 30) {
        return '#85cdf7'; // Intermediate blue
    } else if (value > 25) {
        return '#4f99cc'; // Darker intermediate blue
    } else {
        return '#004085'; // Very dark blue
    }
  }
  
  const headers = ref([
    { text: "Rank", icon: "fa-solid fa-sort-numeric-up fa-xl" },
    { text: "Country", icon: "fa-solid fa-flag fa-xl" },
    { text: "GeoPolitical Sensitivity Index", icon: "fa-solid fa-book-atlas fa-xl", sortable: true },
    { text: "Alliances", icon: "fa-solid fa-handshake fa-xl", sortable: true },
    { text: "Current Conflicts & Sanctions", icon: "fa-solid fa-triangle-exclamation fa-xl", sortable: true },
    { text: "Critical Locks & Knots", icon: "fa-brands fa-joomla fa-xl", sortable: true }
  ]);

  const isOpen = ref(false);
  const countries = ref([]);


  const sortByCriteria = (criteria) => {
    if (criteria === "GeoPolitical Sensitivity Index") {
      getCountriesSorted("GPSI");
      ItemSelected.value = "GeoPolitical Sensitivity Index";
      return;
    }
    getCountriesSorted(criteria);
    ItemSelected.value = criteria;
  }

  function HandleUpdateIndicator(country) {
    selectedCountry.value = country;
    isOpen.value = true;
  }
  
  const toggleOpen = () => {
    isOpen.value = false;
  }

  const selectItem = (text) => {
    fetchData();
    ItemSelected.value = text;
    console.log(ItemSelected.value)
    getCountriesSorted(text);
  };
  const fetchedData = ref([]);
  const Top20Countries = ref([]);
  const selectedCountry = ref(null);
  
  
  async function fetchData() {
    await api.get("/data/loadviews/geopol/ALLCNT")
      .then(response => {
        fetchedData.value = response.data.data 
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  const sortCriteria = {
    "GPSI": "gpsi",
    "Alliances": "alliances",
    "Critical Locks & Knots": "crit_lock_knots",
    "Current Conflicts & Sanctions": "cur_conf_sanct"
  };
  
  function getCountriesSorted(item) {
  
    const criterion = sortCriteria[item];
    if (!criterion)
          return
    
    Top20Countries.value = fetchedData.value
      .sort((a, b) => b[criterion] - a[criterion])
      .map((country, index) => {
        return {
          rank: index + 1,
          country: countryNames[country.iso2] || country.iso2,
          gpsi: country.gpsi.toPrecision(3),
          alliances: country.alliances.toPrecision(3),
          currentConflicts: country.cur_conf_sanct.toPrecision(3),
          criticalLocks: country.crit_lock_knots.toPrecision(3)
        };
      });
  }
  
  onMounted(async () => {
      countries.value = props.data;
      await fetchData();
      getCountriesSorted("GPSI");
  });
  </script>
    
  <style scoped>

.expandable {
  transition: max-width 2s ease;
  overflow: hidden;
  max-width: 0;
  opacity: 0;
}

.expandable.open {
  max-width: 100%;
  opacity: 1;
}

.expandable.close {
  max-width: 0;
  opacity: 0;
}

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
      background: #f3f3f3;
  }

  ::-webkit-scrollbar-thumb {
      background: #979797;
      border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }

  .amcharts-amexport-item {
    border: 2px solid #777 !important;
  }

  .amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    top: -3px!important;
    left: 2px
  }
  
  .blocProducts {
    margin-top: 20px;
    padding: 0 7%;
  }
  
  .item {
    cursor: pointer;
    z-index: 1;
    position: relative;
    transition: color 0.3s ease-in-out;
  }
  
  .sliding-bg {
    position: absolute;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid lightgray;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    transition: transform 0.5s ease, width 0.3s ease;
    z-index: 0;
  }
  
  .custom-table {
    min-width: 900px;
      border-collapse: collapse; 
  }
  .custom-table thead tr, .custom-table thead th {
    border-top: none;
    border-bottom: none !important;
  }
  
  .custom-table tbody th, .custom-table tbody td {
    color: #777;
    font-weight: 400;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 300;
  }
    
  .custom-table tbody th small, .custom-table tbody td small {
    color: #b3b3b3;
    font-weight: 300;
  }
  
  .custom-table tbody .persons {
    padding: 0;
    margin: 0;
  }
      
  .custom-table tbody .persons li {
    padding: 0;
    margin: 0 0 0 -15px;
    list-style: none;
    display: inline-block;
  }
  
  .custom-table tbody .persons li a {
    display: inline-block;
    width: 36px;
  }
  
  .custom-table tbody .persons li a img {
    border-radius: 50%;
    max-width: 100%;
  }
  
  .custom-table tbody tr th, .custom-table tbody tr td {
    position: relative;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
  }
    
  .custom-table tbody tr th:before, .custom-table tbody tr th:after, .custom-table tbody tr td:before, .custom-table tbody tr td:after {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
      content: "";
      left: 0;
      right: 0;
      position: absolute;
      height: 1px;
      background: #bfbfbf;
      width: 100%;
      opacity: 0;
      visibility: hidden;
  }
  
  .custom-table tbody tr th:before, .custom-table tbody tr td:before {
      top: -1px;
  }
  
  .custom-table tbody tr th:after, .custom-table tbody tr td:after {
      bottom: -1px;
  }
  
  .custom-table tbody tr:hover th, .custom-table tbody tr:hover td {
    background: rgba(0, 0, 0, 0.03);
  }
  
  .custom-table tbody tr:hover th:before, .custom-table tbody tr:hover th:after, .custom-table tbody tr:hover td:before, .custom-table tbody tr:hover td:after { 
      opacity: 1;
    visibility: visible;
  }
  
  .custom-table tbody tr.active th, .custom-table tbody tr.active td {
    background: rgba(0, 0, 0, 0.03);
  }
  
  .custom-table tbody tr.active th:before, .custom-table tbody tr.active th:after, .custom-table tbody tr.active td:before, .custom-table tbody tr.active td:after {    
      opacity: 1;
    visibility: visible;
  }
  
  .selected-header {
    color: rgb(0, 0, 0);
      font-size: 12px;
  }
  
  .dimmed-header {
    color: rgb(150, 150, 150);
      font-size: 12px;
  }
  
  .highlighted-value {
    font-weight: bold;
    color: black;
  }
  
  .dimmed-value {
    color: rgb(168, 168, 168);
  }
  
  .name-column {
    width: 300px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .progress {
    height: 0.7rem;
  }
  
  .headers {
    font-size: 12px;
  }
  
  .items {
      font-size: 15px;
  }

  .table-responsive {
    position: relative;
  }

  .sticky-header {
      position: sticky;
      top: 0;
      z-index: 10; 
      background-color: white;
  }

  tbody {
      display: block;
      overflow-y: auto;
      height: 400px;
      width: 100%;
  }

  tr {
      display: table;
      width: 100%;
      table-layout: fixed;
  }
    
  #chartdiv {
      width: 100%;
    height: 500px;
  }
  </style>
    