<template>
  <div :id="chartId" style="width: 95%; height: 200px;"></div>
</template>

<script setup>
/* eslint-disable */
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ref, defineProps, watch, onMounted } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
});

const chartId = ref(props.id);
let root = null;
let series = null;
const hasData = ref(props.data.length > 0);

const createChart = (data) => {
  if (!root) {
    root = am5.Root.new(chartId.value);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
      })
    );

    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "selectX",
      })
    );
    cursor.lineY.set("visible", false);

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 80,
          minorGridEnabled: true,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.get("renderer").grid.template.set("forceHidden", true);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        min: 1,
        max: 100
      })
    );

    series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        stroke: am5.color("#000000"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "[fontSize: 12px]Value : {valueY}\nResume : {summary}",
          pointerOrientation: "left",
        }),
      })
    );

    const tooltip = series.get("tooltip");
    tooltip.label.setAll({
      maxWidth: 700,
      oversizedBehavior: "wrap",
    });

    series.appear(1000);
    chart.appear(1000, 100);
  }

  updateChartData(data);
};


const updateChartData = (data) => {
  let updatedData = data.map((element) => {
    let dateM = new Date(element.timestamp);
    let miliData = dateM.getTime();
    return {
      date: miliData,
      value: element.myvalue.toPrecision(3),
      summary: element.myvalue == 0 ? "" : element.summary || "",
    };
  });

  if (series) {
    series.data.setAll(updatedData);
  }
};

onMounted(() => {
  if (props.data && props.data.length > 0) {
    hasData.value = true;
    createChart(props.data);
  } else {
    hasData.value = false;
  }
});

watch(
  () => props.data,
  (newData) => {
    if (newData && newData.length > 0) {
      hasData.value = true;
      updateChartData(newData);
    } else {
      console.log("No data found");
      hasData.value = false;
    }
  },
  { immediate: true, deep: true }
);
</script>

<style scoped>
.no-data-message {
  font-size: 16px;
  text-align: center;
  color: #999;
  padding: 20px;
}
</style>
