<template>
  <div id="application" :class="$route.name === 'Home' ? 'home-page' : 'other-page'">
    <header>
      <TheNavbar />
    </header>
    <main>
      <router-view />
    </main>
    <!--<footer :class="$route.name === 'Home' ? 'footer-page' : ''">
      <TheFooter />
    </footer> -->
  </div>
</template>

<script>
import TheNavbar from '@/components/UI/StaticUI/TheNavbar.vue'
//import TheFooter from '@/components/TheFooter.vue'

export default {
  name: "app",
  components: { TheNavbar, /*TheFooter*/ },
  data() {
    return {
      isHomeRoute: false
    };
  },
  watch: {
    $route(to) {
      this.isHomeRoute = (to.name === 'Home');
    }
  }
}
</script>

<style>

header {
  position: sticky;
  top: 0;
  z-index: 99;
}

.home-page {
  background-color: #EAEAEA;
  position: relative;
  height: 100vh;
}

.footer-page {
  background-color: #EAEAEA;
}

.other-page {
  height: 100vh;
}

</style>