<template>
  <ul class="pagination">
    <li class="page-item" :class="{ disabled: isInFirstPage }">
      <a class="page-link" type="button" @click="onClickFirstPage">&laquo;</a>
    </li>

    <li class="page-item" :class="{ disabled: isInFirstPage }">
      <a class="page-link" type="button" @click="onClickPreviousPage">Previous</a>
    </li>

    <!-- Visible Buttons Start -->
    <template v-for="page in pages" :key="page.name" >
      <li class="page-item" :class="{ active: isPageActive(page.name) }">
        <a class="page-link" type="button" @click="onClickPage(page.name)">
          {{ page.name }}
        </a>
      </li>
    </template>

    <!-- Visible Buttons End -->
    <li class="page-item" :class="{ disabled: isInLastPage }">
      <a class="page-link" type="button" @click="onClickNextPage">Next</a>
    </li>
    <li class="page-item" :class="{ disabled: isInLastPage }">
      <a class="page-link" type="button" @click="onClickLastPage">&raquo;</a>
    </li>
  </ul>
</template>

<script>
// https://www.digitalocean.com/community/tutorials/vuejs-vue-pagination-component
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },    
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        const start = this.totalPages - (this.maxVisibleButtons - 1);
        if (start <= 0) {
          return 1;
        } else {
          return start;
        }
      }
            
      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
}
</script>

<style>
.pagination {
  font-size: 0.7rem;
  padding: 0 10%;
}
</style>