<template>
  <div v-if="code_access">
    <Tabs :tabs="tabList" v-model:activeTab="activeTab" :name='"Geopolitical Risk"' :backgroundImage='BannerImage'>
      <template #default="{ activeTab }">
        <div v-if="activeTab === 'tab1' && countries.length !== 0" class="blocProducts">
          <GeopoliticalSensitivity :data="countries" />
        </div>
        <div v-if="countries.length !== 0 && activeTab === 'tab2'" class="blocProducts">
          <GeopoliticalAlert :data="countries" />
        </div>
        <div v-if="activeTab === 'tab3'" class="blocProducts">
          <GeopoliticalMethodology />
        </div>
      </template>
    </Tabs>
  </div>
  <div v-else-if="!code_access" class="alert_premium">
    <p class="alert alert-warning text-center mt-3">You must have an access to the <b>Geopolitical product</b> to view this page.
      <br>Please contact us at <b>info@taceconomics.com</b> for more informations.</p>
  </div>
  <div v-else>
  </div>
</template>

<script setup>
import Tabs from '../components/UI/Menu/MenuProducts.vue';
import GeopoliticalSensitivity from '../components/Geopol/GeopoliticalSensitivity.vue';
import GeopoliticalAlert from '../components/Geopol/GeopoliticalAlert.vue';
import { onMounted, ref } from 'vue';
import { countryNames } from '../utils/CountryMapping';
import GeopoliticalMethodology from '../components/Geopol/GeopoliticalMethodology.vue';
import BannerImage from '../../public/img/banner_image/Menu_Geopolitical_Risk.png';
import api from '@/services/api'

const tabList = ref([
  { key: 'tab1', label: 'GeoPolitical Sensitivity Index' },
  { key: 'tab2', label: 'Alert on GeoPolitical Tensions' },
  { key: 'tab3', label: 'Methodology' },
]);

const countries = ref([]);
const code_access = ref("waiting");
const activeTab = ref('tab1');

async function fetchCountries() {
  await api.get("/data/loadviews/geopol/ALLCNT")
    .then(response => {
      countries.value = response.data.data.map((country) => {
        return {
          code: country.iso2,
          code3: country.country_id,
          country: countryNames[country.iso2],
        };
      });
      countries.value.unshift({
        code: "wd",
        code3: "WLD",
        country: "World",
     });
    })
    .catch((error) => {
      console.error(error);
    });



}

onMounted(async() => {
  await api.get("/user/me" )
    .then(response => {
      if ((response.data.email.includes("@taceconomics.com")) | response.data.subscriptions.some(item => item.pack === 'GEOPOL')){
        code_access.value = true
      } else {
        code_access.value = false
      }
    })
  fetchCountries();

});
</script>

<style scoped>
 .blocProducts {
    margin-top: 20px;
    padding: 0 7%;
  }
</style>
