<template>
  <div>
    <h3 class="pt-4">Country Risk Premium, Economic and Political Risk Ratings</h3>
            <hr />
    <SlidingMenu :items="items" @select="handleSelection" />
    <RiskMonitorMap :item="selectedItem" @countryClicked="handleCountryClicked"/>
            <p class="ps-1 simple-text mt-5"></p>
        <div class="table-responsive" v-if="fetchedData.length > 0" style="max-height: 70vh; overflow-y: auto;">
            <table class="table custom-table mt-0" style="table-layout: fixed; width: 100%;">
                <thead class="sticky-header">
                    <tr>
                        <th v-for="header in headers" :key="header.text" @click="sortTable(header)" style="cursor: pointer;">
                            {{ header.text }}
                        </th>
                    </tr>
                </thead>
                <tbody style="height: 400px; overflow-y: auto; display: table-row-group;">
                    <tr class="items" v-for="(item, index) in fetchedData" :key="index">
                        <td class="name-header">{{ item.cntName }}</td>
                        <td>{{ item.crp.toPrecision(4) }}</td>
                        <td>{{ item.ratingeco }}</td>
                        <td>{{ item.ratingpol }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-between">
            <p style="font-size: small;">Country Risk Premium in basis points and Ratings from A (0: lowest risk) to D (100: highest risk)</p>
            <p style="font-size: small;">Source: TAC ECONOMICS</p>
        </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import SlidingMenu from '../UI/Menu/SlidingMenu.vue';
import RiskMonitorMap from './RiskMap.vue';
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
  data: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['countryClicked']);

function handleCountryClicked(country) {
    emit('countryClicked', country);
}

const selectedItem = ref("country risk premium");

const fetchedData = ref([]);

fetchedData.value = props.data;

const items = ref([
  { text: "Country Risk Premium", icon: "fa-solid fa-book-atlas fa-lg" },
  { text: "Economic Risk Rating", icon: "fa-solid fa-handshake fa-lg" },
  { text: "Political Risk Rating", icon: "fa-solid fa-triangle-exclamation fa-lg" }
]);

const handleSelection = (text) => {
  selectedItem.value = text;
};

const headers = [
    { text: "Country", value: "cntName" },
    { text: "Country Risk Premium", value: "crp" },
    { text: "Economic Risk Rating", value: "ratingeco" },
    { text: "Political Risk Rating", value: "ratingpol" },
];

const sortBy = ref(true);

const sortTable = (header) => {
    sortBy.value = !sortBy.value;
    if (sortBy.value) {
        fetchedData.value.sort((a, b) => {
            if (header.text === "Country") {
                return a.cntName.localeCompare(b.cntName);
            } else if (header.text === "Country Risk Premium") {
                return b[header.value] - a[header.value];
            } else {
                if (header.text === "Economic Risk Rating" || header.text === "Political Risk Rating") {
                    if (a[header.value] == null || b[header.value] == null)
                        return;
                    const getValue = (item) => {
                        const match = item[header.value].match(/\(([^)]+)\)/);
                        return match ? parseFloat(match[1]) : 0;
                    };
                    return getValue(b) - getValue(a);
                }
            }
        });
    } else {
        fetchedData.value.sort((a, b) => {
            if (header.text === "Country") {
                return b.cntName.localeCompare(a.cntName);
            } else if (header.text === "Country Risk Premium") {
                return a[header.value] - b[header.value];
            } else {
                if (header.text === "Economic Risk Rating" || header.text === "Political Risk Rating") {
                    if (a[header.value] == null || b[header.value] == null)
                        return;
                    const getValue = (item) => {
                        const match = item[header.value].match(/\(([^)]+)\)/);
                        return match ? parseFloat(match[1]) : 0;
                    };
                    return getValue(a) - getValue(b);
                }
            }
        });
    }
};

</script>

<style scoped>
</style>
