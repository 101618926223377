import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";


export default createStore({
  
  state: {
    email: null,
    token: null,
    refresh_token: null,
  },
  mutations: {
    email(state, payload) {
      state.email = payload;
    },
    token(state, payload) {
      state.token = payload;
    },
    refresh_token(state, payload) {
      state.refresh_token = payload;
    },
  },
  actions: {
    email(context, payload) {
      context.commit('email', payload)
    },
    token(context, payload) {
      context.commit('token', payload)
    },
    refresh_token(context, payload) {
      context.commit('refresh_token', payload)
    },
  },
  getters: {
    email(state) {
      return state.email;
    },
    token(state) {
      return state.token;
    },
    refresh_token(state) {
      return state.refresh_token;
    },
    isLoggedIn(state) {
      return !!state.token;
    },
  },
  plugins: [createPersistedState()]
})
