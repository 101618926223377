<template>
    <div class="d-flex justify-content-center pt-5 align-items-center gap-5">
        <div class="DivChartDivGauge">
            <h2 class="fw-bold text-center my-3" >{{ props.TitleIndicator }} Country Score</h2>
            <div class="" id="chartDivGauge" style="height: 340px;"></div>
            <p class="text-start my-2"><b>Source : </b> Tac Economics</p>
        </div>

        <div class="DivChartDivRadar" >
            <h2 class="fw-bold text-center my-3" >{{ props.TitleIndicator }} Country Score by<br>thematic family</h2>
            <div class="" id="chartDivRadar" style="height: 340px;"></div>
            <p class="text-start my-2"><b>Source : </b> Tac Economics</p>

        </div>
    </div>
</template>

<script setup>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import { onMounted, defineProps, watch } from "vue";
import json from "../JSON/indicator_decomposition_webapp.json";

const props = defineProps({
    indicator: Number,
    country: Object,
    TitleIndicator: String,
})

let gaugeChart;
let radarChart;

onMounted(() => {
    props.country.data.forEach(indicator => {
        const description = getDescription(indicator.symbol);
        indicator.description = description;
    });
    renderChart("gauge")
    renderChart("radar")
})

watch(() => props.indicator, () => {
    gaugeChart.dispose();
    radarChart.dispose();
    props.country.data.forEach(indicator => {
        const description = getDescription(indicator.symbol);
        indicator.description = description;
    });
    renderChart("gauge");
    renderChart("radar");
})


function renderChart(chart){
    if(chart == "gauge"){
        gaugeChart = am4core.create("chartDivGauge", am4charts.GaugeChart); 
        gaugeChart.innerRadius = 0;
        gaugeChart.hiddenState.properties.opacity = 0;
        gaugeChart.fontSize = 11;
        gaugeChart.innerRadius = am4core.percent(85);
        gaugeChart.resizable = true;
        gaugeChart.startAngle =  150;
        gaugeChart.endAngle = 390;

        var axis = gaugeChart.xAxes.push(new am4charts.ValueAxis());
        axis.min = 1;
        axis.max = 5;
        axis.strictMinMax = true;
        axis.renderer.ticks.template.disabled = false;
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.ticks.template.strokeWidth = 0;
        axis.renderer.ticks.template.length = 5;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.labels.template.radius = am4core.percent(10);
        axis.renderer.labels.template.fontSize = "1.2em";

        var range1 = axis.axisRanges.create();
        range1.value = 1;
        range1.endValue = 2.5;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = am4core.color("#cc0000");
        range1.axisFill.zIndex = -1;

        var range2 = axis.axisRanges.create();
        range2.value = 2.51;
        range2.endValue = 3;
        range2.axisFill.fillOpacity = 1;
        range2.axisFill.fill = am4core.color("#eb4b52");
        range2.axisFill.zIndex = -1;

        var range3 = axis.axisRanges.create();
        range3.value = 3.01;
        range3.endValue = 3.5;
        range3.axisFill.fillOpacity = 1;
        range3.axisFill.fill = am4core.color("#f1c232");
        range3.axisFill.zIndex = -1;

        var range4 = axis.axisRanges.create();
        range4.value = 3.51;
        range4.endValue = 4;
        range4.axisFill.fillOpacity = 1;
        range4.axisFill.fill = am4core.color("#3be33b");
        range4.axisFill.zIndex = -1;

        var range5 = axis.axisRanges.create();
        range5.value = 4.01;
        range5.endValue = 5;
        range5.axisFill.fillOpacity = 1;
        range5.axisFill.fill = am4core.color("#029602");
        range5.axisFill.zIndex = -1;

        var hand = gaugeChart.hands.push(new am4charts.ClockHand());
        hand.innerRadius = am4core.percent(55);
        hand.pin.disabled = true;
        hand.radius = am4core.percent(82);
        hand.value = props.indicator;
        hand.fill = am4core.color("#696969");
        hand.stroke = am4core.color("#696969");

        // Create a label in the center of the chart
        let label = gaugeChart.radarContainer.createChild(am4core.Label);
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 25;
        label.text = props.indicator;
    }
    else if(chart == "radar"){
        radarChart = am4core.create("chartDivRadar", am4charts.RadarChart);
        radarChart.data = props.country.data.filter(risk => risk.symbol.startsWith('fam') && risk.myvalue != null);

        var categoryAxis = radarChart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "description";
        categoryAxis.renderer.labels.template.fontSize = 11;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.labels.template.wrap = true;
        categoryAxis.renderer.labels.template.maxWidth = 150;
        categoryAxis.renderer.labels.template.textAlign = "middle";

        var valueAxis = radarChart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.axisFills.template.fillOpacity = 0.05;
        valueAxis.renderer.labels.template.fontSize = 11; 
        valueAxis.renderer.labels.template.paddingRight = 30;
        
        /* Create and configure series */
        var series = radarChart.series.push(new am4charts.RadarSeries());
        series.dataFields.valueY = "myvalue";
        series.dataFields.categoryX = "description";
        series.name = "Country";
        series.strokeWidth = 3;
        series.stroke = am4core.color("#FF0000");

    }
}

function getDescription(symbol){
    const indicator = json.find(item => item.symbol === symbol);
    return indicator ? indicator.description : 'Description not available';
}

</script>

<style scoped>
.DivChartDivGauge, .DivChartDivRadar{
    width: 40%;
}

.DivChartDivGauge p, .DivChartDivRadar p{
    font-size: 14px;
}
</style>