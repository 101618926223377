<template>
    <iframe v-if="access" src="https://chat.taceconomics.gwenlake.cloud/" style="width: 100%; height: 100vh;"></iframe>
    <div v-else class="buttons_disabled alert alert-warning">
          <p>You need a subscription to <strong>Alber</strong>
             to be able tu use this functionality. Please contact us at support@taceconomics.com to request access.</p>
        </div>
</template>

<script>
import api from '@/services/api'
export default {
    data() {
        return  {
            access: true,
        }
    },
    mounted() {
        api.get("/user/me?alber=1")
            .then(response => {
        this.user = response.data;
        if(this.user.email.endsWith('@taceconomics.com'))
            this.access = true;
        else
            if (this.user.subscriptions.length > 0)
                if (this.user.subscriptions.some(subscription => subscription.pack === "STD"))
                        this.access = true;
                else
                    this.access = false;
            else
                this.access = false;
      })
    }
}
</script>
<style lang="scss" scoped>
</style>