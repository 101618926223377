<template>
  <div id="chartdiv45"></div>
</template>

<script setup>
import { onMounted, defineProps, watch, onBeforeUnmount } from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const props = defineProps({
  graphData: {
    type: Array,
    required: true
  }
})

let root;

const initChart = () => {
  am5.ready(() => {
    root = am5.Root.new("chartdiv45");
  
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      paddingLeft: 0,
      layout: root.verticalLayout
    }));


    var data = props.graphData;

    const maxDataValue = Math.max(
      ...props.graphData.flatMap(item => 
        ["rrecc_eco", "rrecc_pol", "rrecc_commo", "rrecc_debt", "rrecc_ukr", "rrecc_crisis", "rrecc"]
          .map(key => item[key] || 0)
      )
    );

    console.log(maxDataValue);

    var xRenderer = am5xy.AxisRendererX.new(root, { minorGridEnabled: true });
    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "timestamp",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xRenderer.grid.template.setAll({ location: 1 });
    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: maxDataValue * 1.1,
      numberFormat: "#",
      strictMinMax: true,
      calculateTotals: true,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      })
    }));
  
    var legend = chart.children.push(am5.Legend.new(root, {
      y: 0,
      x: am5.percent(10),
      nameField: "name",
      fillField: "fill",
      strokeField: "fill",
      position: "absolute",
    }));

    legend.itemContainers.template.setAll({
      paddingTop: 5,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0
    });

    const makeSeries = (name, fieldName, color) => {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        valueYShow: "valueY",
        categoryXField: "timestamp"
      }));

      series.columns.template.setAll({
        tooltipText: "{categoryX} - {name}: {valueY}",
        tooltipY: am5.percent(10),
        fill: color,
        stroke: color
      });

      series.data.setAll(data);
      series.appear();
    };

    const makeLineSeries = (name, fieldName, color) => {
      var series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        valueYShow: "valueY",
        categoryXField: "timestamp",
        stroke: color
      }));

      series.strokes.template.setAll({
        strokeWidth: 3
      });

      series.data.setAll(data);
      series.appear();
    };

    makeSeries("Economic Risk", "rrecc_eco", am5.color("#a12318"));
    makeSeries("Political Risk", "rrecc_pol", am5.color("#142556"));
    makeSeries("Commodity exports dependence premium", "rrecc_commo", am5.color("#9c93f7"));
    makeSeries("Repeated debt restructurings premium", "rrecc_debt", am5.color("#539a79"));
    makeSeries("War in Ukraine Risk Premium", "rrecc_ukr", am5.color("#d8d8d8"));
    makeSeries("Crisis signal premium", "rrecc_crisis", am5.color("#edb847"));
    makeLineSeries("Country Risk Premium", "rrecc", am5.color("#3c4340"));

  
    chart.appear(1000, 100);
  });
};

watch(() => props.graphData, () => {
  if (root) {
    root.dispose();
  }
  initChart();
});

onBeforeUnmount(() => {
    if (root) {
      root.dispose();
    }
  });

onMounted(initChart);
</script>

<style scoped>
#chartdiv45 {
  width: 100%;
  height: 500px;
}
</style>
