<template>
  <div v-if="code_access">
    <Tabs :tabs="tabList" v-model:activeTab="activeTab" :name='"RiskMonitor"' :backgroundImage='BannerImage'>
      <template #default="{ activeTab }">
        <div v-if="activeTab === 'tab1'" class="blocProducts">
          <RiskMonitor v-if="fetchedData.length > 0" :data="fetchedData" @countryClicked="handleCountryClicked" />
        </div>
        <div v-if="activeTab === 'tab2'" class="blocProducts">
          <CountryDetails v-if="countries.length > 0" :data="countries" :country="countryClicked"/>
        </div>
      </template>
    </Tabs>
  </div>
  <div v-else-if="!code_access" class="alert_premium">
    <p class="alert alert-warning text-center mt-3">You must have an access to the <b>Country Risk product</b> to view this page.
      <br>Please contact us at <b>info@taceconomics.com</b> for more informations.</p>
  </div>
  <div v-else>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import Tabs from "../components/UI/Menu/MenuProducts.vue";
import RiskMonitor from "../components/RiskMonitor/RiskMonitor.vue";
import CountryDetails from "../components/RiskMonitor/RiskCountryDetails.vue";
import BannerImage from "../../public/img/banner_image/Menu_RiskMonitor.png";
import api from '@/services/api';

const fetchedData = ref([]);
const countries = ref([]);
const code_access = ref("waiting");
const countryClicked = ref(null);
const activeTab = ref('tab1');

function handleCountryClicked(country) {
  countryClicked.value = country;
}

watch(
  () => countryClicked.value,
  (newVal) => {
    if (newVal) {
      activeTab.value = 'tab2';
    }
  }
);

async function fetchData() {
  await api.get("/data/loadviews/countryrisk/ALLCNT")
    .then(response => {
      fetchedData.value = response.data.data.tab_cnt;
      fetchedData.value.sort((a, b) => a.cntName.localeCompare(b.cntName));
    })
    .catch((error) => {
      console.error(error);
    });
}

async function fetchCountries() {
  await api.get("/data/loadviews/countryrisk/ALLCNT")
    .then(response => countries.value = response.data.data.map.map((country) => ({
      code: country.iso2,
      code3: country.country_id,
      country: country.cntName,
    })))
    .catch((error) => {
      console.error(error);
    });
}

const tabList = ref([
  { key: 'tab1', label: 'Global View' },
  { key: 'tab2', label: 'Country Details' }
]);

onMounted(async() => {
  await api.get("/user/me")
    .then(response => {
      if ((response.data.email.includes("@taceconomics.com")) || response.data.subscriptions.some(item => item.pack === 'STD')) {
        code_access.value = true;
      } else {
        code_access.value = false;
      }
    });
  fetchData();
  fetchCountries();
});
</script>

<style scoped>
.blocProducts {
    margin-top: 20px;
    padding: 0 7%;
}
</style>