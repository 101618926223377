<template>
  <div>
    <p>{{ labelText }}</p>
    <div class="dropdown mb-3">
      <button
        class="dropdown-toggle"
        type="button"
        id="dropdownButton"
        data-bs-toggle="dropdown"
        style="font-weight: normal;"
      >
        <i :class="iconClass"></i>{{ selectedItem ? selectedItem : defaultLabel }}
      </button>
      <ul class="dropdown-menu pb-5 mb-5" style="max-height: 400px; overflow-y: auto; z-index: 1; position: relative;">
        <div class="mb-2 inputs d-flex ps-2 pe-2 sticky-search" style="align-items: center;">
          <i style="color: rgba(220, 220, 220, 0.75)" class="fa fa-search"></i>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            v-model="searchTerm"
            @input="onSearchInput"
          />
        </div>
        <template v-for="item in filteredItems" :key="item[keyField]">
          <div class="item" @click="selectItem(item[keyField])">
            <label style="margin-left: 10px; cursor: pointer;">
              <span style="font-size: 18px; margin-left: 15px;">
                {{ item[displayField] }}
              </span>
            </label>
          </div>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  items: Array,
  keyField: String,
  displayField: String,
  labelText: String,
  defaultLabel: String,
  iconClass: String,
  defaultItem: String,
});

const emit = defineEmits(['itemSelected']);

const searchTerm = ref('');
const selectedItem = ref(props.defaultItem);
const items = ref(props.items);

const filteredItems = computed(() => {
  return [...items.value]
    .sort((a, b) => a.country.localeCompare(b.country))
    .filter(item =>
      item[props.displayField].toLowerCase().includes(searchTerm.value.toLowerCase())
    );
});

function selectItem(itemKey) {
  selectedItem.value = items.value.find(item => item[props.keyField] === itemKey)[props.displayField];
  emit('itemSelected', itemKey);
}

function onSearchInput() {
}
</script>

<style scoped>
.dropdown-menu {
  padding: 0 !important;
}

.sticky-search {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.no-data-message {
  font-size: 16px;
  text-align: center;
  color: #999;
  padding: 20px;
}

.form-control {
  border: none;
  height: 45px;
  border-radius: 0px;
  border-bottom: 1px solid #eee;
}

.form-control:focus {
color: #495057;
background-color: #fff;
border-color: #eee;
outline: 0;
box-shadow: none;
border-bottom: 1px solid blue;
}

.country:hover {
  background-color: #f8f9fa;
}

.country {
  cursor: pointer;
}

.dropdown button {
  border: 1px solid #ececec;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  font-size: 1.2em;
  background-color: rgb(255, 255, 255);
}

.dropdown-toggle::after {
  margin-left: 15px;
}
</style>