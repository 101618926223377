<template>
  <div class="d-flex align-items-center justify-content-center mt-5">
    <div class="mx-auto border rounded bg-white shadow mt-5 mb-5 p-5 login-form">
      <img class="logo mx-auto d-block" src="../assets/img/taceconomics.png">
      <form @submit.prevent="signup">
        <h2 class="pt-2 text-center mb-4">Sign Up</h2>
        <input class="mb-2 form-control" type="text" name="email" placeholder="Email" />
        <input class="mb-2 form-control" type="password" name="password" placeholder="Password" />
        <input class="mb-2 form-control" type="password" name="password_repeat" placeholder="Password (repeat)" />
        <button type="submit" class="mt-4 mb-4 btn btn-dark w-100" id="login_button">Create account</button>
        <div class="text-center">Already have an account? <a class="underline" href="/login">Log in</a></div>
        <div v-if="message" class="alert alert-success mt-4 text-center">{{ message }}</div>
        <div v-if="error" class="alert alert-warning mt-4 text-center">{{ error }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import Cookies from 'js-cookie';

export default {
  name: "SignUp",
  components: {},
  data() {
    return {
      username: '',
      password: '',
      password_repeat: '',
      message: '',
      error: '',
    };
  },
  methods: {
    async signup(e) {

      // Recupération des valeurs entrés par l'utilisateur
      this.email = e.target.elements.email.value;
      this.password = e.target.elements.password.value;
      this.password_repeat = e.target.elements.password_repeat.value;
      this.message = "";
      this.error = "";

      // Requête sur l'API pour inscrire l'utilisateur (non validé, mais quand même inscrit)
      api.post('/user/signup', {
        'email': this.email,
        'password': this.password,
        'password_repeat': this.password_repeat
      })
        .then(response => {

          this.response = response.data;
          this.token = this.response.usertoken;

          this.message = response.data.message;
          this.$store.dispatch('email', this.email);
          this.$store.dispatch('token', this.response.usertoken);
          this.$store.dispatch('refresh_token', this.response.refresh_token);

          // Garde en mémoire des valeurs emails et mdp pour renvoyer s'il le souhaite
          Cookies.set("mail", this.email);
          Cookies.set("password", this.password);
          Cookies.set("password_repeat", this.password_repeat);

          // Envoi du mail de confirmation au mail rentré par l'utilisateur
          api.post('/user/resendverify', {
            'email': this.email,
          })
            .then(response => {

              this.response = response.data;

              this.token = this.response.usertoken;

              this.$store.dispatch('email', this.email);
              this.$store.dispatch('token', this.response.usertoken);
              this.$store.dispatch('refresh_token', this.response.refresh_token);

              // Redirection sur la page où on peut renvoyer un mail
              this.$router.push('/signupmail');
            })

          setTimeout(() => this.$router.push({ path: '/signupmail' }), 5000);
        })
        .catch((error) => {
          this.error = error.response.data.error;
        });
    },
  }
};
</script>


<style scoped>
.logo {
  width: 5rem;
  height: auto;
  margin-top: -85px;
  margin-bottom: 20px;
}

.login-form {
  min-width: 400px;
}
</style>
