<template>
    <div class="d-flex align-items-center justify-content-center mt-5">
        <div class="mx-auto border rounded bg-white shadow mt-5 mb-5 p-5 login-form">
            <img class="logo mx-auto d-block" src="../assets/img/taceconomics.png">
            <form @submit.prevent="sendmail">
                <h2 class="pt-2 text-center mb-4">Reset Password</h2>
                <input class="mb-2 form-control" type="text" name="email" placeholder="Email" />
                <button type="submit" class="mt-4 mb-4 btn btn-dark w-100" id="login_button">Send reset password
                    email</button>
                <div v-if="error" class="alert alert-warning mt-4 text-center">{{ error }}</div>
            </form>
        </div>
    </div>
</template>

<script>
import api from '@/services/api'
import Cookies from 'js-cookie';

export default {
    name: "LogIn",
    components: {},
    data() {
        return {
            email: "",
            firstname: "",
            error: ""
        };
    },
    methods: {
        async sendmail(e) {

            this.email = e.target.elements.email.value;

            // Envoi du mail de réinitalisation de mot de passe
            api.post('/user/sendresetpwdmail', {
                'email': this.email
            })
                .then(response => {

                    this.response = response.data;
                    this.token = this.response.usertoken;

                    this.$store.dispatch('email', this.email);
                    this.$store.dispatch('token', this.response.usertoken);
                    this.$store.dispatch('refresh_token', this.response.refresh_token);

                    // Garde en mémoire de la valeur email pour renvoyer s'il le souhaite
                    Cookies.set("mail", this.email);
                    this.$router.push('/forgotpwdmail');
                })
                .catch((error) => {
                    this.error = error.response.data.error;
                });
        },
    },
};
</script>
  
<style scoped>
.logo {
    width: 5rem;
    height: auto;
    margin-top: -85px;
    margin-bottom: 20px;
}

.login-form {
    min-width: 400px;
}
</style>
  