<template>
<div class="d-flex">
    <AsideNavbar/>


    <div class="div-container d-flex flex-wrap justify-content-start ms-auto px-3">
      <section class="col-12">
          <div class="p-3">
              <h2 class="fs-3" >Country Map</h2>
              <hr>
          </div>
          <div class="d-flex px-3 list_buttons">
              <DropdownMenu @update-indicator="updateIndicator" :risk="currentRisk"/>
              <div class="dropdown">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Select a risk
                  </button>
                  <ul class="dropdown-menu range p-1">
                      <div v-for="(risk, index) in risks" :key="risk" class="p-1 d-flex" @click="updateRisk(risk)">
                          <input class="me-1" type="radio" :id="index" :value="risk" :checked="risk === currentRisk">
                          <label class="w-100">{{ risk }}</label>
                      </div>
                  </ul>
              </div>
          </div>
      </section>
      <section class="my-3 px-3 col-12">
          <div class="maps-section mb-5">
              <CountryMap 
                  v-if="isCountryDataLoaded == true"
                  :data="allData" 
                  :risks="risks"
                  :risk="currentRisk"
                  :indicator="currentIndicator"/>
          </div>
          <div class="tables-section">
              <CountryTab 
                v-if="isCountryDataLoaded == true"
                  :data="allData" 
                  :risks="risks"
                  :indicator="currentIndicator" />
          </div>
    </section>
    </div>
  </div>
</template>

<script setup>
import CountryMap from '../components/Country/CountryMap.vue';
import CountryTab from '../components/Country/CountryTab.vue';
import DropdownMenu from '../components/UI/Dropdown/DropdownMenu.vue';
import api from '../services/api.js';
import { onMounted, ref } from 'vue';
import AsideNavbar from '../components/AsideNavbar.vue';

const allData = ref([]);
const risks = ref(["global","reputation","materialisation","extreme","chronique"]);
const currentRisk = ref("global");
const currentIndicator = ref({symbol :"E", description: "Environmental Risk"});
const isCountryDataLoaded = ref(false);

async function getAllData(risks){
  for(let risk of risks){
    let url_api = `/data/app_esg/loadview/${risk}/allcnt?date=2023-01-01&page_name=homepage`
    let response = await api.get(url_api);
    let values = response.data.data;

    if (!Array.isArray(allData.value[risk])) {
      allData.value[risk] = [];
    }

    values.map((value) => {
      if(value.name !== undefined && value.name !== null){
        if(value.myvalue === null || value.myvalue === undefined){
          value.myvalue = 0;
        }
        allData.value[risk].push(value);
      }
    });
  }
}

const updateIndicator = (newValue) => {
    currentIndicator.value = newValue;
};

const updateRisk = (newValue) => {
  currentRisk.value = newValue;
};

onMounted(async () => {
    await getAllData(risks.value);  
    isCountryDataLoaded.value = true;
});

</script>

<style scoped>

.div-container{
    width: 90vw;
}


.page{
    height: 100%;
}

.button_list{
    background-color: #f8f9fa;
}


#map-indicator-large{
    height: 80%;
}

.range div:hover{
  background-color: lightgray;
}

.list_links{
    list-style-type: none;
    padding: 0;
}

.list_buttons button{
    border: solid 2px #021559;
    padding: 0.3em 0.8em;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    font-size: 1em;
}

.list_buttons button:hover{
    background-color: rgb(229, 229, 229);
}

.list_buttons button:focus{
    color: white;
    background-color: #021559;
    box-shadow: none;
}

.range label:hover, .range input:hover{
  cursor: pointer;
}

.range input[type="radio"] {
    display: none;
}

.range input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    position: relative;
    bottom: 5px;
    border-radius: 50%;
    border: 2px solid #021559;
    background-color: #fff;
    vertical-align: bottom;
}

.range input[type="radio"]:checked + label::before {
    background-color: #021559;
}

</style>