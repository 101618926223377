import axios from "axios";
import store from '../store';
import router from '../router';


const ax = axios.create({
  baseURL: "https://api.taceconomics.com",
  headers: { "Content-Type": "application/json", },
});

// const ax = axios.create({
//   baseURL: "http://localhost:5000",
//   headers: { "Content-Type": "application/json", },
// });

ax.interceptors.request.use(
  function(config) {
      if (store.getters.token) config.headers.Authorization = `Bearer ${store.getters.token}`
      // console.log('refresh token ' + store.getters.refresh_token);
      // console.log('access token ' + store.getters.token);
      return config
  },
  function(error) {
      return Promise.reject(error)
  }
)


ax.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      //console.log(err.response.config.url)
      // return other errors
      if (err.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
      // error on login
      if (err.response.config.url === '/user/login') {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
      // error on refresh
      if (err.response.config.url === '/user/refresh') {
        console.log('ERRO NO REFRESH')
        store.dispatch('email');
        store.dispatch('token');
        store.dispatch('refresh_token');
        router.push('/');
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
      // refresh
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.refresh_token;
      return ax.post('/user/refresh').then(
        success => {
          const config = err.response.config
          config.headers.Authorization = 'Bearer ' + success.data.access_token
          store.commit('token', success.data.access_token)
          return ax(config)
        }
      );
    }
)

export default ax;