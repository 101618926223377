<template>
  <div class="d-flex align-items-center justify-content-center mt-5">
    <div class="mx-auto border rounded bg-white shadow mt-5 mb-5 p-5 login-form">
      <img class="logo mx-auto d-block" src="../assets/img/taceconomics.png">
      <form @submit.prevent="login">
        <h2 class="pt-2 text-center mb-4">Log In</h2>
        <input class="mb-2 form-control" type="text" name="email" placeholder="Email" />
        <input class="mb-2 form-control" type="password" name="password" placeholder="Password" />
        <button type="submit" class="mt-4 mb-4 btn btn-dark w-100" id="login_button">Log in</button>
        <div class="text-center">No account? <a class="underline" href="/signup">Create one</a></div>
        <div class="text-center"><a class="underline" href="/forgotpwd">Forgot Password?</a></div>
        <div v-if="error" class="alert alert-warning mt-4 text-center">{{ error }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: "LogIn",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  methods: {
    async login(e) {
      this.email = e.target.elements.email.value;
      this.password = e.target.elements.password.value;
      api.post('/user/login', {
        'email': this.email,
        'password': this.password
      })
      .then(response => {
        this.response = response.data;
        this.$store.dispatch('email', this.email);
        this.$store.dispatch('token', this.response.access_token);
        this.$store.dispatch('refresh_token', this.response.refresh_token);
        this.$router.push('/');
      })
      .catch((error) => {
        this.error = error.response.data.error;
      });        
    },
  },
};
</script>

<style scoped>
.logo {
  width: 5rem;  
  height: auto;  
  margin-top: -85px;
  margin-bottom: 20px;
}

.login-form {
  min-width: 400px;
}
</style>
