<template>
  <div class="d-flex align-items-center justify-content-center mt-5">
    <div class="mx-auto border rounded bg-white shadow mt-5 mb-5 p-5 login-form">
      <img class="logo mx-auto d-block" src="../assets/img/taceconomics.png">
      <form @submit.prevent="resetpassword">
        <div v-if="statusOk">
          <h2 class="pt-2 text-center mb-4">Change your password</h2>
          <input class="mb-2 form-control" type="password" name="password" placeholder="Password" />
          <input class="mb-2 form-control" type="password" name="password_repeat" placeholder="Password (repeat)" />
          <button type="submit" class="mt-4 mb-4 btn btn-dark w-100" id="login_button">Change password</button>
        </div>
        <div v-if="message" class="alert alert-success mt-4 text-center">{{ message }}</div>
        <div v-if="error" class="alert alert-warning mt-4 text-center">{{ error }}</div>
        <div v-if="statusErr" class="alert alert-warning mt-4 text-center">Token expired, please try again to send a
          password change email.</div>
        <div class="text-center">Retry <a class="underline" href="/forgotpwd">here</a></div>
      </form>
    </div>
  </div>
</template>
    
<script>
import api from '@/services/api';
import Cookies from "js-cookie";

export default {
  data() {
    return {
      password: '',
      password_repeat: '',
      message: '',
      error: '',
      statusOk: '',
      statusErr: ''
    };
  },
  created() {
    // Vérification da la validité du token 
    api.post('/user/expireToken', {
      token: this.getToken()
    })
      .then(response => {
        // Affichage du message d'erreur si le token est expiré
        if (response.status == 400) {
          this.statusErr = response.status;
        }
        else if(response.status == 201) {
          this.statusOk = response.status;
        }
      })
      .catch((error) => {
        this.error = error.response.data.error;
      });
  },
  methods: {
    resetpassword(e) {
      // Récupération du nouveau mot de passe
      this.password = e.target.elements.password.value;
      this.password_repeat = e.target.elements.password_repeat.value;
      this.message = "";
      this.error = "";

      Cookies.remove('mail');

      // Réinitalisation du mot de passe à partir du token
      api.post('/user/forgotpwd', {
        'token': this.getToken(),
        'password': this.password,
        'password_repeat': this.password_repeat
      })
        .then(response => {
          this.response = response.data;
          this.$store.dispatch('email', this.email);
          this.$store.dispatch('token', this.response.access_token);
          this.message = response.data.message;
          setTimeout(() => this.$router.push({ path: '/login' }), 5000);
        })
        .catch((error) => {
          this.error = error.response.data.error;
        });
    },
    /**
     * Return the token from the URL
     */
    getToken() {
      return this.$route.query.token;
    }
  }
};
</script>
  
  
<style scoped>
.logo {
  width: 5rem;
  height: auto;
  margin-top: -85px;
  margin-bottom: 20px;
}

.login-form {
  min-width: 400px;
}

.underline {
  text-decoration: underline;
}
</style>
  