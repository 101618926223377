<template>
    <nav class="nav flex-column px-1 aside-nav justify-content-start gap-5 position-fixed border-end border-3 border-dark ">
        <ul class="mb-0 my-3 p-1 list-unstyled d-flex flex-column gap-5">
            <li class="p-1" ><a class="text-dark text-center position-relative" href="/AppESG"><img src="/img/country_profile/epingle.png" alt="" width="35" class="me-2"><span  >Country Map</span></a></li>
            <li class="p-1" ><a class="text-dark text-center position-relative" href="/CountryProfile/France"><img src="/img/country_profile/country.png" alt="" width="35" class="me-2"><span  >Country Profile</span></a></li>
            <li class="p-1" ><a class="text-dark text-center position-relative " href="/CountryIndicator"><img src="/img/country_profile/indicator.png" alt="" width="35" class="me-2"><span >Indicator View</span></a></li>
        </ul>
    </nav>
</template>

<style scoped>
.aside-nav {
  height: 90vh;
  width: 80px; 
  transition: width 0.4s; 
  z-index: 99;
}

.aside-nav:hover {
  width: 10vw;
}

.aside-nav a span{
    opacity: 0;
}

.aside-nav:hover a span{
    opacity: 1;
}

.aside-nav a span{
    position: absolute;
    left: 45px;
    opacity: 0;
    transition: opacity 0.2s linear;
}

.aside-nav:hover span {
  opacity: 1;
}

.aside-nav li:hover span{
    text-decoration: underline
}

.country_list{
    max-height: 400px;
    overflow: auto
}
</style>