<template>
    <div class="col-12 text-white">
        <div class="thead d-inline-flex align-items-center col-12 bg-primary p-3 mb-3">
            <h3 class="col-5 text-white mb-0">Indicator</h3>
            <ul class="d-inline-flex list-unstyled col-7 gap-5 mb-0">
                <li class="flex-fill w-100">Global Risk</li>
                <li class="flex-fill w-100">Reputation Risk</li>
                <li class="flex-fill w-100">Materalisation Risk</li>
                <li class="flex-fill w-100">Extreme Risk</li>
                <li class="flex-fill w-100">Chronique Risk</li>
            </ul>
        </div>
        <div class="tbody bg-dark">
            <ul class="lvl1menu list-unstyled col-12">
                <li v-for="item in allData" :key="item.id" class="d-flex flex-wrap col-12 p-2" >
                    <p class="col-5 mb-0 p-1">{{ item.description }} </p>
                    <ul class="d-inline-flex list-unstyled col-7 gap-5 mb-0 p-1">
                        <p class="flex-fill w-100 mb-0" v-for="(riskValue, riskType) in item.risks" :key="riskType">
                            {{ riskValue }}
                        </p>
                    </ul>
                    <ul class="lvl2menu list-unstyled col-11 ms-auto">
                        <li v-for="(child1, index2) in item.children" :key="child1.id" class="d-flex flex-wrap col-12 p-2">
                            <p class="col-5 mb-0 p-1">{{ child1.description }} </p>
                            <ul class="d-inline-flex list-unstyled col-7 mb-0 p-1" style="gap: 6rem !important;">
                                <p class="flex-fill w-100 mb-0" v-for="(riskValue, riskType) in child1.risks" :key="riskType">
                                    {{ riskValue }}
                                </p>
                            </ul>
                            <ul class="list-unstyled col-11 ms-auto text-dark" :class="'lvl3menu-'+index2">
                                <li v-for="(child2, index3) in child1.children" :key="child2.id" class="d-flex flex-wrap col-12 p-2" >
                                    <p class="col-4 mb-0 p-1">{{ child2.description }} </p>
                                    <ul class="d-inline-flex list-unstyled col-8  mb-0 py-1" style="gap: 3.8rem!important">
                                        <p class="flex-fill w-100 mb-0" v-for="(riskValue, riskType) in child2.risks" :key="riskType">
                                            {{ riskValue }}
                                        </p>
                                    </ul>
                                    <ul class="lvl4menu list-unstyled col-11 ms-auto" :class="'lvl4menu-'+index3">
                                        <li v-for="child3 in child2.children" :key="child3.id" class="d-flex flex-wrap col-12 p-2">
                                            
                                            <p class="col-3 mb-0">{{ child3.description }} </p>
                                            <ul class="d-inline-flex list-unstyled col-9 gap-5 mb-0">
                                                <p class="flex-fill w-100 mb-0 ps-3" v-for="(riskValue, riskType) in child3.risks" :key="riskType">
                                                    {{ riskValue }}
                                                </p>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <button @click="exportCSV()" class="btn btn-secondary ms-auto" >
         <i class="fa-solid fa-file-csv me-2"></i> Export to csv
        </button>
</template>

<script setup>
import * as d3 from 'd3-dsv';
import { saveAs } from 'file-saver';
import { ref, defineProps } from "vue";
import json from "./../JSON/indicator_decomposition_webapp.json";

const props = defineProps({
    country: Object,
    risks: Array,
});

const allData = ref([]);

allData.value = organizeData(json, props.country.risks);

function organizeData(data, risks){
  const levels = {};
  const itemsById = {};

  data = data.filter(item => !item.symbol.startsWith('b2_'));

  data.forEach((item) => {
    const pathSegments = item.path.split('/');
    const level = pathSegments.length;

    if (!levels[level]) {
      levels[level] = [];
    }

    // Utilisez le chemin comme identifiant
    item.id = item.path;
    item.children = [];

    item.risks = {};
    for (let riskType of Object.keys(risks)) {
        if (item[riskType] === 1) {
            const risk = risks[riskType].data.find(r => r.symbol === item.symbol);
            if (risk !== undefined && risk.myvalue !== null) {
                item.risks[riskType] = Math.round(risk.myvalue * 100) / 100;
            }else{
                item.risks[riskType] = "-";
            }
        }else{
            item.risks[riskType] = "-";
        }
    }

    levels[level].push(item);
    itemsById[item.id] = item;
  });

  // Créez la hiérarchie des éléments
  for (let level in levels) {
    if (level > 1) {
      levels[level].forEach(item => {
        const parentId = item.path.split('/').slice(0, -1).join('/');
        const parentItem = itemsById[parentId];
        if (parentItem) {
          parentItem.children.push(item);
        }
      });
    }
  }

  return levels[1];
}

function exportCSV() {
    let csvData = [];

    // Fonction récursive pour parcourir allData.value
    function traverse(data) {
        // Créer un nouvel objet pour stocker les données de l'indicateur
        let indicatorData = {
            Indicator: data.description
        };
        // Boucler sur props.risks pour obtenir les risques
        props.risks.forEach(risk => {
            // Vérifier si la valeur du risque existe et est différente de "-"
            if (data.risks[risk] && data.risks[risk] !== '-') {
                // Utiliser la notation de bracket pour accéder à la propriété
                indicatorData[risk] = Math.round(data.risks[risk] * 100) / 100;
            }
        });

        // Ajouter indicatorData à csvData
        csvData.push(indicatorData);

        // Si data a une propriété children, parcourir children
        if (data.children) {
            data.children.forEach(traverse);
        }
    }
    // Appeler traverse sur allData.value
    traverse(allData.value[0]);
    const csv = d3.csvFormat(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'TACECONOMICS_'+props.country.name+'_indicators.csv');
}

</script>

<style scoped>

.col-4 {
    width: 32.9% !important;
}

.thead li{
    font-size: 0.9rem;
}

#lvl1menu{
    width: 10%!important;
}

.lvl2menu .col-5{
    width: 38.5%!important;
}

.lvl2menu.col-11{
    width: 95% !important;
}


.lvl1menu li {
    background-color: #375623;
}

/* Couleurs pour chaque indicateur de niveau 2 */
.lvl2menu li:nth-child(1) {
    background-color: #548235;
}

.lvl2menu li:nth-child(2) {
    background-color: #2F75B5;
}

.lvl2menu li:nth-child(3) {
    background-color: #C00000;
}

/* Couleurs pour chaque indicateur de niveau 3 */
.lvl3menu-0 li:nth-child(1) {
    background-color: #A9D08E;
}

.lvl3menu-0 li:nth-child(2) {
    background-color: #C6E0B4;
}

.lvl3menu-0 li:nth-child(3) {
    background-color: #E2EFDA;
}

.lvl3menu-1 li:nth-child(1) {
    background-color: #8EA9DB;
}

.lvl3menu-1 li:nth-child(2)  {
    background-color: #D9E1F2;
}

.lvl3menu-2 li:nth-child(1)  {
    background-color: #FF7D7D;
}

.lvl3menu-2 li:nth-child(2) {
    background-color: #FFA3A3;
}

.lvl3menu-2 li:nth-child(3) {
    background-color: #FFC5C5;
}

/* Couleurs pour chaque indicateur de niveau 4 */
.lvl4menu li:nth-child(even) {
    background-color: #EBFBED;
}

/* Cible les éléments impairs de niveau 4 */
.lvl4menu li:nth-child(odd) {
    background-color: #C1F1C8;
}

.lvl3menu-1 .lvl4menu li:nth-child(1) {
    background-color: #D6DCE4;
}

.lvl3menu-1 .lvl4menu li:nth-child(2) {
    background-color: #C9F1FF;
}

.lvl3menu-1 .lvl4menu li:nth-child(3) {
    background-color: #C9FBFB;
}

.lvl3menu-1 .lvl4menu-1 li:nth-child(1) {
    background-color: #C9F1FF;
}

.lvl3menu-1 .lvl4menu-1 li:nth-child(2) {
    background-color: #C9FBFB;
}

</style>