<template>
  <section class="profil_user container d-flex flex-wrap p-3 raw">
  <div class="col-12">
    <h1 class="mb-3 h2">User Profile</h1>
    <hr>
  </div>
  <div class="d-flex align-items-center justify-content-center col-12 align-self-start">
    <div class="card mt-5 mb-5 p-5 w-50">
      <div class="card-body w-100">
        <div v-if="user.lastname">
          <img src="../assets/img/utilisateur.png" alt="" width="20" class="me-2"><b>Name :</b>
          <p class="mb-3">{{ user.firstname }} {{ user.lastname }}</p>
        </div>
        <div v-if="user.company_name">
          <img src="../assets/img/enterprise.png" alt="" width="20" class="me-2"><b>Company :</b>
          <p class="mb-3">{{ user.company_name }}</p>
        </div>
        <img src="../assets/img/enveloppe.png" alt="" width="20" class="me-2"><b>Email :</b>
        <p class="mb-3">{{ user.email }}</p>
        <div v-if="user.api_key">
          <img src="../assets/img/api-key.png" alt="" width="20" class="me-2"><b>API Key :</b>
          <p class="">{{ user.api_key }}</p>
        </div>
        <div v-if="validated == 0" class="text-center alert alert-warning mt-4 text-center">Account not verified</div>
        <div v-if="validated == 0" class="text-center"><a class="underline" href="/signupmail">Resend mail to validate</a>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import api from '@/services/api'
import { mapGetters } from "vuex";

export default {
  name: "VUser",
  computed: {
    ...mapGetters(["email"]),
  },
  data() {
    return {
      user: {
        email: "",
        firstname: "",
        lastname: "",
        company_name: "",
        api_key: "",
        subscriptions: []
      },
      validated: 1,
    }
  },
  created() {
    api.get("/user/me")

      .then(response => {

        this.user = response.data;

        api.post("/user/isvalidate", {
          'email': this.user.email
        })
          .then(response => this.validated = response.data.isvalidate)
          .catch((error) => {
            console.log(error);
          });

      })
      .catch((error) => {
        this.validated = 1;
        console.log(error);
      });
  },
  methods: {
    async getUser() {
      await api.get("/user/me")
        .then(response => this.user = response.data)
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getUser();
  },
}

</script>

<style scoped>
.profil_user{
  height: 80vh;
}
</style>