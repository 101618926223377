<template>
  <br />
  <div class="text-center text-muted mt-4">PRICING</div>
  <h1 class="text-center mt-2">Find your perfect plan</h1>

  <div class="plans row g-1 mt-3 mb-3 text-center">

    <div class="col">
      <div class="card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3 border-dark">
          <h4 class="my-0 fs-4">Free</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">0€ <small class="text-muted">/ mo </small></h1>
          <div class="card-subtitle">Discover our datalab</div>
          <ul class="list-unstyled mt-3 mb-4">
            <li>Limited Access </li>
            <li>Web platform, Graphs</li>
            <li>Official National and International Statistics</li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div v-if="!isLoggedIn" class="card-footer">
          <button @click="signup" class="btn btn-outline-primary w-100">Start for free</button>
        </div>
        <div v-if="isLoggedIn" class="card-footer">
          <button class="btn btn-outline-primary w-100">Free</button>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3 border-dark">
          <h4 class="my-0 fs-4">Starter</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">99€ <small class="text-muted">/ mo </small></h1>
          <div class="card-subtitle">Instant Access !</div>
          <ul class="list-unstyled mt-3 mb-4">
            <li>Access to API (1000 series/mo)</li>
            <li>Web platform, Excel add-in, R/Python libraries</li>
            <li>Official National and International Statistics</li>
            <li>Selected TAC ECONOMICS Datasets</li>
            <li>Standard Support</li>
          </ul>
        </div>
        <div class="card-footer">
          <button @click="starter" class="btn btn-primary w-100">Subscribe</button>
          <!-- <button @click="contact" class="btn btn-dark w-100">Contact sales</button> -->
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card_enterprise card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3 text-bg-primary">
          <h4 class="my-0 fs-4">Enterprise</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">Contact us</h1>
          <div class="card-subtitle ">Bring access to all our datasets</div>
          <ul class="list-unstyled mt-3 mb-4">
            <li>Unlimited Access to API</li>
            <li>Web platform, Excel add-in, R/Python libraries</li>
            <li>Official National and International Statistics</li>
            <li>Open access to TAC ECONOMICS Datasets</li>
            <li>Advanced Support (Research, Dashboards...)</li>

          </ul>
        </div>
        <div class="card-footer">
          <button @click="contact" class="btn btn-dark w-100 btn-primary">Contact sales</button>
        </div>
      </div>
    </div>
  </div>
  <br /> <br />
  <h2 class="board_pricing display-6 text-center mb-4">Compare plans</h2>

  <div class="table-responsive board_pricing">
    <table class="table text-center table-bordered table-hover" style="transition: all 0.2s ease-in-out;">
      <thead>
        <tr>
          <th style="width: 34%;"></th>
          <th style="width: 22%;">Free</th>
          <th style="width: 22%;">Starter</th>
          <th style="width: 22%;">Enterprise</th>
        </tr>
      </thead>
      <tbody>
        <td colspan="4" class=fw-bold style="text-align: left;">Access</td>
        <tr>
          <th scope="row" class="text-start fw-light">Web platform</th>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Charts</th>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Excel Add-In</th>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Programmatic access (R, Python)</th>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Access to API</th>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <td colspan="4" class=fw-bold style="text-align: left;">Datasets</td>

        <tr>
          <th scope="row" class="text-start fw-light">International Statistics</th>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Official National Statistics</th>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Short-Term Macro Forecasts (TAC ECONOMICS)</th>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Selected Economic Indicators (TAC ECONOMICS)</th>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Country Risk, Early Warnings, Cost of Capital, ESG, Country
            Sentiment, etc. (TAC ECONOMICS)</th>
          <td></td>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <td colspan="4" class=fw-bold style="text-align: left;">Support</td>

        <tr>
          <th scope="row" class="text-start fw-light">Support</th>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Research Services</th>
          <td></td>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Customized Dashboards</th>
          <td></td>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Private Workspace</th>
          <td></td>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
        <tr>
          <th scope="row" class="text-start fw-light">Email Alerts</th>
          <td></td>
          <td></td>
          <td><i class="fa-solid fa-check" style="color: #029232;"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VPricing",
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    async signup() {
      this.$router.push('/signup')
    },
    async starter() {
      // this.$router.push({ "path": '/signup', query: { plan: 'starter' } })
      window.location = "https://buy.stripe.com/4gweUZfyEatvg24005"
    },
    async standard() {
      this.$router.push({ "path": '/signup', query: { plan: 'standard' } })
    },
    async contact() {
      window.location = "https://taceconomics.net/contact"
    },
  },
}
</script>


<style scoped>
.badge {
  font-weight: 400;
  font-size: 0.7rem;
  border: 1px solid var(--te-color-dark-4);
  background: white;
  color: var(--te-color-dark-2);
  padding: 4px;
}

.plans,
.board_pricing {
  padding: 0 10%;
}

.card {
  margin: 0.5rem;
  padding: 1.2rem;
  text-align: center;
  background: #f0eefe;
}

.card-header {
  background: #f0eefe;
}

.card-subtitle {
  color: #533fd9;
}

.card-footer {
  background: transparent;
  border: 0;
}

.plan-name {
  color: var(--te-color-dark-2);
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
}

.table-text {
  color: #f0eefe;
}

.plan-price {
  color: var(--te-color-blue-0);
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 2.0rem;
}

.text-blue {
  color: var(--te-color-blue-0);
}

.plan-description {
  color: var(--te-color-dark-2);
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.0rem;
}

ul {
  padding: 0;
  margin-top: 1rem;
  list-style-type: none;
  text-align: left;
  font-weight: 300;
  font-size: 0.8rem;
}

li {
  color: var(--te-color-dark-2);
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
}

.card_enterprise {
  border: solid 3px rgb(188, 0, 0);
}</style>
